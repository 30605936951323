import HoldSection from "../HoldSection/HoldSection";
import { memo } from "react";
import { ManageSection } from "apps/orderdetail/helper/commonConstants";
import { useUpdateRequireDeliveryFlag } from "apps/orderdetail/modules/orderDetailsContainer/hook/useUpdateRequireDeliveryProof";
import { useUpdateShowQRCode } from "apps/orderdetail/modules/orderDetailsContainer/hook/useUpdateShowQRCode";
import { useUpdateEmailRecieveOptout } from "apps/orderdetail/modules/orderDetailsContainer/hook/useUpdateEmailRecieveOptout";
import { useUpdateShowInvoicePrices } from "apps/orderdetail/modules/orderDetailsContainer/hook/useUpdateShowInvoicePrices";
import Title from "../Title/Title";
import WaitingIndicator from "../WaitingIndicator/WaitingIndicator";
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";
import Switch from "../Switch/Switch";

export const OrderManageSection = memo(
  ({
    orderId,
    orderData = {},
    sendEmail,
    setSendEmail,
    showUnitPrice,
    setShowUnitPrice,
    deliveryProof,
    setDeliveryProof,
    showQRCode,
    setShowQRCode,
    loading,
  }: any) => {
    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;
    const seeActionsUrl = `${httpsUrl}/manager/mOrder_actions?orderID=${orderId}`;

    const { updateRequireDelivery, isLoading: deliveryFlagLoading } =
      useUpdateRequireDeliveryFlag();
    const { updateShowQR, isLoading: qrCodeLoading } = useUpdateShowQRCode();
    const { updateEmailRecieve, isLoading: emailRecieveLoading } =
      useUpdateEmailRecieveOptout();
    const { updateShowInvoicePrice, isLoading: showInvoiceLoading } =
      useUpdateShowInvoicePrices();

    const custEmail = orderData?.buyer?.custEmail || "";

    const handleDeliverProofToggle = async () => {
      const payload = { orderId, requireProofOfDeliveryFlag: +!deliveryProof };
      setDeliveryProof(!deliveryProof);
      try {
        await updateRequireDelivery(payload);
      } catch (error) {
        setDeliveryProof(deliveryProof);
      }
    };

    const handleShowQACode = async () => {
      const payload = { orderId, showQRCode: !showQRCode };
      setShowQRCode(!showQRCode);
      try {
        await updateShowQR(payload);
      } catch (error) {
        setShowQRCode(showQRCode);
      }
    };

    const handleEmailRecieveOptout = async () => {
      const payload = {
        orderId,
        personId: orderData.buyer.ordererId,
        noMailFlag: +sendEmail,
      };
      setSendEmail(!sendEmail);
      try {
        await updateEmailRecieve(payload);
      } catch (error) {
        setSendEmail(sendEmail);
      }
    };

    const handleShowUnit = async () => {
      const payload = { orderId, status: !showUnitPrice };
      setShowUnitPrice(!showUnitPrice);
      try {
        await updateShowInvoicePrice(payload);
      } catch (error) {
        setShowUnitPrice(showUnitPrice);
      }
    };

    return (
      <div className="order-manage" id="manage" key="manage">
        <Title title="Manage" />
        <div className="order-manage-details">
          <div data-manage-line='send-email' className="d-flex justify-content-between align-items-center order-manage-details-line">
            <div className="d-flex flex-column">
              <span className="common-text">Send email updates</span>
              <span className="emails-redirect">
                {sendEmail
                  ? `Emails will be sent to ${custEmail}`
                  : "Emails will redirect to tech@jumper.com"}
              </span>
            </div>
            <div className="d-flex align-items-center order-manage-toggle-group">
              {emailRecieveLoading || loading ? (
                <WaitingIndicator size={18} />
              ) : (
                <span className="emails-redirect">
                  {sendEmail ? "Yes" : "No"}
                </span>
              )}
              <Switch
                value={sendEmail}
                onChange={handleEmailRecieveOptout}
                id="sendEmailUpdates-toggle"
                disabled={loading}
              />
            </div>
          </div>
          <div data-manage-line='show-qr' className="d-flex justify-content-between align-items-center order-manage-details-line">
            <span className="common-text">Show QR code on Packing slip</span>
            <div className="d-flex align-items-center order-manage-toggle-group">
              {qrCodeLoading || loading ? (
                <WaitingIndicator size={18} />
              ) : (
                <span className="emails-redirect">{showQRCode ? "Yes" : "No"}</span>
              )}
              <Switch
                value={showQRCode}
                onChange={handleShowQACode}
                id="showQrCode-toggle"
                disabled={loading}
              />
            </div>
          </div>
          <div data-manage-line='show-unit-price' className="d-flex justify-content-between align-items-center order-manage-details-line">
            <div className="d-flex flex-column">
              <span className="common-text">Show Unit price on Invoice</span>
              <span className="emails-redirect">
                Only applies to this order
              </span>
            </div>
            <div className="d-flex align-items-center order-manage-toggle-group">
              {showInvoiceLoading || loading ? (
                <WaitingIndicator size={18} />
              ) : (
                <span className="emails-redirect">
                  {showUnitPrice ? "Yes" : "No"}
                </span>
              )}
              <Switch
                value={showUnitPrice}
                onChange={handleShowUnit}
                id="showUnit-toggle"
                disabled={loading}
              />
            </div>
          </div>
          <div data-manage-line='order-require-delivery-proof' className="d-flex justify-content-between align-items-center order-manage-details-line">
            <span className="common-text">Require Delivery Proof</span>
            <div className="d-flex align-items-center order-manage-toggle-group">
              {deliveryFlagLoading || loading ? (
                <WaitingIndicator size={18} />
              ) : (
                <span className="emails-redirect">
                  {deliveryProof ? "Yes" : "No"}{" "}
                </span>
              )}
              <Switch
                value={deliveryProof}
                onChange={handleDeliverProofToggle}
                id="deliveryProof-toggle"
                disabled={loading}
              />
            </div>
          </div>
        </div>
        <div className="pt-3 pb-3 order-manage-hold">
          <HoldSection
            btnText={ManageSection.holdOrder}
            text={ManageSection.putOnAdminHold}
            onClick={"#"}
          />
          <HoldSection
            className="pt-3"
            btnText={ManageSection.holdShipping}
            text={ManageSection.putOnShippingHold}
            onClick={"#"}
          />
          <HoldSection
            className="pt-3"
            btnText={ManageSection.seeActions}
            text={ManageSection.actionTakenOnThisOrder}
            onClick={seeActionsUrl}
          />
        </div>
      </div>
    );
  }
);
