import { Paths } from "../enums";

import InvoiceHistory from "apps/pos/InvoiceHistory";
import ItemHistory from "apps/pos/pages/itemHistory/itemHistory";
import ItemLedger from "apps/pos/pages/itemLedger";
import QuoteHistory from "apps/pos/pages/quoteHistory";
import PosPriceChangeHistory from "apps/pos/PosPriceChangeHistory";
import PurchaseHistory from "apps/pos/PurchaseHistory";
import PickReplaceQuantity from "apps/pos/ReturnProduct/PickReplaceQuantity/PickReplaceQuantity";
import ReturnProduct from "apps/pos/ReturnProduct/ReturnProduct";
import ReturnProductCart from "apps/pos/ReturnProduct/ReturnProductCart";
import ReturnSuccessfully from "apps/pos/ReturnProduct/ReturnSuccessfully/ReturnSuccessfully";
import { ProductListingContainer } from "apps/product/modules/productListingContainer";
import { PaymentHistory, UserList } from "../apps/admin";
import AddProductPage from "../apps/pos/AddProductPage";
import NewOrder from "../apps/pos/NewOrder";
import OrderCompleted from "../apps/pos/OrderCompleted";
import PickQuantity from "../apps/pos/PickQuantity";
import PosRedirect from "../apps/pos/PosRedirect";
import PrivacySettings from "../apps/pos/PrivacySettings/privacySettings";
import SalesHistory from "../apps/pos/SalesHistory";
import Reviewitems from "../apps/pos/UpdateProduct/ReviewItems/ReviewItems";
import { QuoteDetail } from "../apps/springboard";

import { Settings } from "apps/admin/Setting/Settings";

import ViewOrderDetails from 'apps/orderdetail/modules/orderDetailsContainer';
import CashDrawer from "apps/pos/cashDrawer/cashDrawer";
import Customer from "apps/pos/Customer";
import MovingAvgCostChangeHistory from "apps/pos/MovingAvgCostChangeHistory";
import DeliverItemsInStorage from "apps/pos/order/DeliverItemsInStorage";
import { chargeCreditCard } from "apps/pos/pages/ChargeCreditCard";
import SubStorageItems from "apps/pos/pages/customerInfo/SubStorageItems/SubStorageItems";
import Drafts from "apps/pos/pages/Drafts/Drafts";
import PutInStorage from "apps/pos/storage/PutInStorage";
import ProductDetails from "apps/product/modules/productDetailsContainer";
import PackingSlip from "apps/pos/PackingSlip/PackingSlip";
// required later import ProductDetailsNew from "apps/product/Pages/ProductDetails/NewIndex";

interface PageRoute {
  path: string;
  component: any;
  isDistributorId?: boolean;
}

export const pageRoutes: PageRoute[] = [
  {
    path: Paths.adminHome,
    component: UserList,
  },
  {
    path: `${Paths.adminUsers}/:id/${Paths.adminReviewIdUsers}`,
    component: UserList,
  },
  {
    path: `${Paths.adminUsers}/:id`,
    component: UserList,
  },
  {
    path: Paths.adminUsers,
    component: UserList,
  },
  {
    path: Paths.adminInvitedUsers,
    component: UserList,
  },
  {
    path: Paths.adminReviewUsers,
    component: UserList,
  },
  {
    path: `${Paths.adminReviewUsers}/:id`,
    component: UserList,
  },
  {
    path: Paths.pos,
    component: PosRedirect,
  },
  {
    path: Paths.posNewOrder,
    component: NewOrder,
  },
  {
    path: Paths.posCart,
    component: AddProductPage,
  },
  {
    path: `${Paths.posCart}/:cartId`,
    component: AddProductPage,
  },
  {
    path: Paths.posOrderComplete,
    component: OrderCompleted,
  },
  {
    path: Paths.posPickQuantity,
    component: PickQuantity,
  },
  {
    path: Paths.history,
    component: PaymentHistory,
  },
  {
    path: Paths.privacySettingsPage,
    component: PrivacySettings,
  },
  {
    path: Paths.cashDrawer,
    component: CashDrawer,
  },
  //Need to pass distributorId as props
  {
    path: Paths.settings,
    component: Settings,
    isDistributorId: true,
  },
  {
    path: `${Paths.posSalesHistory}/:id`,
    component: SalesHistory,
  },
  {
    path: `${Paths.posQuotesHistory}/:id`,
    component: QuoteHistory,
  },
  {
    path: `${Paths.purchaseHistory}/:id`,
    component: PurchaseHistory,
  },
  {
    path: `${Paths.invoiceHistory}/:id`,
    component: InvoiceHistory,
  },
  {
    path: Paths.posReviewItems,
    component: Reviewitems,
  },
  {
    path: `${Paths.posItemLedger}/:id`,
    component: ItemLedger,
  },
  {
    path: `${Paths.posItemHistory}/:id`,
    component: ItemHistory,
  },
  {
    path: `${Paths.quoteDetail}`,
    component: QuoteDetail,
  },
  {
    path: `${Paths.quoteDetail}/job/:id`,
    component: QuoteDetail,
  },
  {
    path: Paths.posReturnProducts,
    component: ReturnProduct,
  },
  {
    path: Paths.posReturnCart,
    component: ReturnProductCart,
  },
  {
    path: Paths.posReplacePickQuantity,
    component: PickReplaceQuantity,
  },
  {
    path: Paths.posReturnComplete,
    component: ReturnSuccessfully,
  },
  {
    path: Paths.product,
    component: ProductListingContainer,
  },
  {
    path: `${Paths.product}/:id`,
    component: ProductDetails,
  },
  {
    path: Paths.customer,
    component: Customer,
  },
  {
    path: Paths.putInStorage,
    component: PutInStorage,
  },
  {
    path: Paths.deliverItemsInStorage,
    component: DeliverItemsInStorage,
  },
  {
    path: Paths.subStorageItems,
    component: SubStorageItems,
  },
  //required later
  //   {
  //     path: Paths.productDetailsNew,
  //     component: ProductDetailsNew,
  //   },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.purchaseHistoryReport}`,
    component: PurchaseHistory,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.ledger}`,
    component: ItemLedger,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.itemHistory}`,
    component: ItemHistory,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.quotesHistory}`,
    component: QuoteHistory,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.salesHistory}`,
    component: SalesHistory,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.invoiceHistoryReport}`,
    component: InvoiceHistory,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.posPriceChangeReport}`,
    component: PosPriceChangeHistory,
  },
  {
    path: `${Paths.product}/:id/${Paths.reports}/${Paths.movingAvgCostChangeReport}`,
    component: MovingAvgCostChangeHistory,
  },
  {
    path: Paths.posDrafts,
    component: Drafts,
  },
  {
    path: `${Paths.orderDetail}/:orderId`,
    component: ViewOrderDetails
  },
  {
    path: Paths.posChargeCreditCard,
    component: chargeCreditCard,
  }
  ,
  {
    path: `${Paths.posPackingSlip}/:orderId/:shipmentId`,
    component: PackingSlip
  }
];
