import React from 'react';
import RowComp from './RowComp';

const GeneralRow = ({ value, loading, personId = "", handleRedirect }) => {

    return (
        <RowComp
            name={value || "Not set"}
            isAssigned={value}
            handleAssign={handleRedirect}
            assignText='Change'
            handleChange={handleRedirect}
            changeText='Change'
            isManager={false}
            loading={loading}
            initialsHover={personId}
        />
    );
}

export default GeneralRow