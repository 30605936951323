import { useQuery } from "@tanstack/react-query";
import { getCustomerDetail } from "./service";

export const useGetCustomerDetail = (payload) => {
    const { isError, isLoading, isSuccess, data } = useQuery(
        ["get-customer-details", payload],
        async () => {
            return getCustomerDetail(payload);
        },
        {
            retry: false,
        }
    );
    return {
        isError,
        isLoading,
        isSuccess,
        data
    };
};
