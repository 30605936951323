import "apps/orderdetail/components/overviewTable/table.scss";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

const OverviewTable = ({ data, loading }) => {
  return (
    <div className="overview">
      {data.map((item, index) =>
      (
        <div className={`overview-item ${item?.className ? item?.className : ""}`} key={index}>
          <span className="overview-item-key p2">{item.label}</span>
          <span className="overview-item-value p2">
            {loading ?
              <>
                <ShapedSkeleton
                  width={"130"} // Adjust width to fit the content
                  height={37} // Height to fit the avatar and message
                  shape="0 0 130 30"
                  radius={4} // Rounded corners for rectangles
                  fullWidth={false}
                />
              </> :
              item.value}
          </span>
        </div>
      )
      )}
    </div>
  );
};

export default OverviewTable;
