import React, { useRef, useState } from 'react'
import { Popover } from "@material-ui/core";
import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';
import "./ReplacementPopup.scss"
import { formatDate, formatPlural } from 'utils/utils';
import { formatCurrency } from 'utils/numberFormat';
import OrderStatus from './OrderStatus';

const ReplacementPopup = (({ replacedOrdersList }) => {
    return (
        <div className='replacement-popup'>
            <div className='replacement-popup-title'>
                <MaterialIcon icon={"restart_alt"} size="26" classes="replace-restart-alt" />
                <p className="subtitle2">{formatPlural(replacedOrdersList.length, `Replacement Order`, `Replacement Orders`)}</p>
            </div>
            <div className='replacement-popup-list foggy'>
                <ul>
                    {replacedOrdersList.map((order, index) => (
                        <li key={index}>
                            <a href={`order/${order.orderId}`}>
                                <p className='order-id'>#{order.orderId}</p>
                                <p className='replacement-date'>{formatDate(order.createDate)}</p>
                                <p className='text-align-right replacement-value'>{formatCurrency(order.totalAmt)}</p>
                                <OrderStatus orderStatusName={order.orderStatus} />
                                <div className='replacement-popup-expand-more'>
                                    <MaterialIcon icon={"expand_more"} size='20' />
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div >
    )
})

export const ReplacementOrder = (({ replacedOrdersList }) => {
    const [showPopup, setShowPopup] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <button
                className="replaced-orders"
                onClick={() => setShowPopup((prev) => !prev)}
                ref={buttonRef}
            >
                <p>
                    <MaterialIcon icon={"restart_alt"} size="26" classes="replace-restart-alt" />
                    <span className="subtitle2">{formatPlural(replacedOrdersList.length, `Replacement Order`, `Replacement Orders`)}</span>
                </p>
                <MaterialIcon icon={"expand_more"} size="20" classes="replace-expand-more" />
            </button>
            <Popover
                open={showPopup}
                anchorEl={buttonRef.current}
                onClose={() => setShowPopup(false)}
                className="replacement-order-mui-popover"
                elevation={0}
                disableScrollLock={true}
                transitionDuration={0}
            >
                <ReplacementPopup replacedOrdersList={replacedOrdersList} />
            </Popover>
        </>
    )
})