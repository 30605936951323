import React, { useState, useEffect, useMemo, memo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { navItems } from "../helper/commonConstants";
import Icon from "./Icon/Icon";
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton"

type NavItem = {
  id: string;
  label: string;
  icon: string;
  href?: string;
  shortcut: string;
};

const Nav = ({
  handleScroll,
  className,
  activeSection,
  orderId,
  orderData,
  loading
}: {
  handleScroll: (id: string, behavior: any, cb: any) => void;
  className?: string;
  activeSection: string;
  orderId: string;
  orderData: any;
  loading: boolean;
}) => {
  const distributor = useSelector(distributorData);
  const { httpsUrl } = distributor;

  const [altPressed, setAltPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey) setAltPressed(true);
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.altKey) setAltPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  navItems.forEach((item: NavItem) => {
    if (item.shortcut !== "") {
      const combo = `alt+${item.shortcut}`;
      useHotkeys(
        combo,
        (event) => {
          event.preventDefault();
          if (item.href) {
            const url = item.href
              .replace("{baseUrl}", httpsUrl)
              .replace("{orderId}", orderId);
            window.open(url, "_blank", "noopener,noreferrer");
          } else {
            handleScroll(item.id, "instant", null);
          }
        },
        { keydown: true, keyup: false },
        [handleScroll, httpsUrl, orderId]
      );
    }
  });

  const filteredNavItems = useMemo(() => {
    if (!orderData) return [];
    if (!orderData.showQRCodeOnPackingSlip) return navItems;
    return navItems.filter((ele) => ele.displayForStockOrder)
  }, [orderData]);

  if (loading) {
    return (
      <>
        <nav className={className}>
          <ul className="order-detail-navigation">
            {navItems.map((ele) => {
              return (
                <li key={`navItem—${ele.id}`}>
                  <ShapedSkeleton
                    width={"200"}
                    height={29}
                    shape="0 4 200 22"
                    radius={6}
                    fullWidth={false}
                  />
                </li>
              )
            })}
          </ul>
        </nav>
      </>
    )
  }

  return (
    <nav className={className}>
      <ul className="order-detail-navigation">
        {filteredNavItems.map((item: NavItem) => (
          <li key={`navItem—${item.id}`}>
            {item.href ? (
              <a
                data-active={activeSection === item.id}
                data-nav-shortcut={item.shortcut}
                className="order-detail-nav-li text-secondary interactive"
                href={
                  item.href
                    ? item.href
                      .replace("{baseUrl}", httpsUrl)
                      .replace("{orderId}", orderId)
                    : undefined
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name={item.icon} size="16" />
                <NavItemLabel
                  label={item.label}
                  shortcut={item.shortcut}
                  showUnderline={altPressed}
                />
              </a>
            ) : (
              <button
                data-active={activeSection === item.id}
                data-nav-shortcut={item.shortcut}
                className="order-detail-nav-li text-secondary interactive"
                onClick={() => handleScroll(item.id, "instant", null)}
              >
                <Icon name={item.icon} size="16" />
                <NavItemLabel
                  label={item.label}
                  shortcut={item.shortcut}
                  showUnderline={altPressed}
                />
              </button>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default memo(Nav);

interface NavItemLabelProps {
  label: string;
  shortcut?: string;
  showUnderline?: boolean;
}

const NavItemLabel = memo(({
  label,
  shortcut,
  showUnderline = false,
}: NavItemLabelProps) => {
  const shortcutIndex = label.toLowerCase().indexOf(shortcut.toLowerCase());

  return (
    <p className="p-1 whitespace-nowrap mb-0 mt-0">
      {shortcutIndex !== -1 ? (
        <>
          {label.slice(0, shortcutIndex)}
          <span
            style={{
              textDecoration: showUnderline && shortcut ? "underline" : "none",
            }}
          >
            {label[shortcutIndex]}
          </span>
          {label.slice(shortcutIndex + 1)}
        </>
      ) : (
        label
      )}
    </p>
  );
});
