import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateRequireDeliveryFlag } from "./service";

export const useUpdateRequireDeliveryFlag = () => {
    const { mutateAsync, isLoading, data, error, isSuccess } = useMutation(
        async (payload) => {
            return updateRequireDeliveryFlag(payload);
        },
    );

    const updateRequireDelivery = useCallback(
        async (data) => {
            await mutateAsync(data);
        },
        [mutateAsync]
    );

    return {
        updateRequireDelivery,
        isLoading,
        data,
        error,
        isSuccess,
    };
};
