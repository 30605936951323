import dayjs from "dayjs";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core";
import images from "constants/images";
import StyledTableCell from "./style";
import PersonComp from "../PersonComp/PersonComp";

interface DocumentWithTableProps {
  docName: string;
  docDescription: string;
  docSize: number;
  docSource: number;
  docTypeName: string;
  docUrl: string;
  uploaderName: string;
  createTime: string;
  docID: number;
  jobQuoteID: number;
  ticketID: number;
  uplodadPersonID: number;
}

export const DocumentWithTable = ({
  data,
  className,
}: {
  data: DocumentWithTableProps[];
  className?: string;
}) => {
  const { noDocument } = images;
  const currentDate = dayjs();

  const StyledTableRow = withStyles(() => ({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }))(TableRow);

  const handleDifferentTime = ({ getCreatedTime, period }) => {
    return currentDate.diff(getCreatedTime, period);
  };

  if (!data.length) {
    return <></>
  }

  return (
    <div className={`order-document-child-data ${className}`}>
      <TableContainer>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <StyledTableCell align="left">{""}</StyledTableCell>
              <StyledTableCell align="left">NAME, DESCRIPTION</StyledTableCell>
              <StyledTableCell align="left">UPLOADED BY</StyledTableCell>
              <StyledTableCell align="left">TYPE</StyledTableCell>
              <StyledTableCell align="left">SOURCE</StyledTableCell>
              <StyledTableCell align="left">FILETYPE</StyledTableCell>
              <StyledTableCell align="left">{""}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data &&
              data.map((item, index) => {
                const {
                  docName,
                  docDescription,
                  docUrl,
                  uploaderName,
                  createTime,
                  docTypeName,
                  docID,
                  jobQuoteID,
                  ticketID,
                  docSource,
                  uplodadPersonID
                } = item || {};
                const fileNameWithExtension = docName.split("/").pop();
                const [fileName, fileExtension] =
                  fileNameWithExtension.split(".");
                const showImage =
                  fileExtension === "pdf" ? noDocument : docUrl;

                const imageClass =
                  fileExtension === "pdf" ? "no_document" : "show-image";
                const handleFileType = () => {
                  switch (docSource) {
                    case 1:
                      return "This order";
                    case 2:
                      return `Quote # ${jobQuoteID}`;
                    case 3:
                      return `Ticket # ${ticketID}`;
                    default:
                      return "";
                  }
                };
                const getCreatedTime = dayjs(createTime);
                const timeDiffInDays = currentDate.diff(getCreatedTime, "day");
                const timeDiffInWeeks = handleDifferentTime({
                  getCreatedTime,
                  period: "week",
                });
                const timeDiffInMonths = handleDifferentTime({
                  getCreatedTime,
                  period: "month",
                });
                const timeDiffInYears = handleDifferentTime({
                  getCreatedTime,
                  period: "year",
                });

                const handleTime = () => {
                  if (timeDiffInDays < 7) {
                    return `${timeDiffInDays} day${timeDiffInDays > 1 ? "s" : ""
                      } ago`;
                  } else if (timeDiffInDays >= 365) {
                    return `${timeDiffInYears} year${timeDiffInYears > 1 ? "s" : ""
                      } ago`;
                  } else if (timeDiffInDays >= 28 && timeDiffInDays < 365) {
                    return `${timeDiffInMonths} month${timeDiffInMonths > 1 ? "s" : ""
                      } ago`;
                  } else {
                    return `${timeDiffInWeeks} week${timeDiffInWeeks > 1 ? "s" : ""
                      } ago`;
                  }
                };

                return (
                  <StyledTableRow key={`${docID}-${index}`} >
                    <StyledTableCell className="table-body-name-user-image">
                      <div>
                        <div className="image" />
                        <img
                          src={showImage}
                          alt="no_document"
                          className={imageClass}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="table-body-name-description bordered-row">
                      <span className="table-body-name-description-first">
                        {fileName}
                      </span>
                      <br />
                      <span className="table-body-name-description-first common-text-style">
                        {docDescription}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell className="bordered-row">
                      <PersonComp personName={uploaderName} personId={uplodadPersonID} />
                    </StyledTableCell>
                    <StyledTableCell className="common-text-style bordered-row">
                      {docTypeName}
                    </StyledTableCell>
                    <StyledTableCell className="common-text-style bordered-row">
                      {handleFileType()}
                    </StyledTableCell>
                    <StyledTableCell className="common-text-style table-body-name-file-type bordered-row">
                      {fileExtension}
                    </StyledTableCell>
                    <StyledTableCell className="common-text-style bordered-row">
                      {handleTime()}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
