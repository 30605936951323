const HoldSection = ({
  text,
  onClick,
  btnText,
  className,
}: {
  text: string;
  onClick: any;
  btnText: string;
  className?: string;
}) => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <span className="common-text">{text}</span>
      <span>
        <a className="btn od-btn-secondary interactive" href={onClick} onClick={typeof onClick === "function" ? onClick : null} target="_blank">
          <span>{btnText}</span>
        </a>
      </span>
    </div>
  );
};

export default HoldSection;
