const CardLine = (props) => {
  const { label, value, color } = props;
  return (
    <li
      className={`d-flex justify-content-between align-items-center py-1 px-2 mx-n2 rounded-lg`}
    >
      <p className="p2 d-flex flex-gap-2-items-center">
        {color ? (
          <span
            className={`w-3 h-3 shrink-0 d-flex rounded-circle ${color}`}
          ></span>
        ) : null}
        <span>{label}</span>
      </p>
      <p className="subtitle2">{value}</p>
    </li>
  );
};

export default CardLine;
