import check from "apps/springboard/images/icons/check.png";
import downloadIcon from "apps/springboard/images/icons/download-black.png";
import filterIcon from "apps/springboard/images/icons/filter.png";
import lastPriceIcon from "apps/springboard/images/icons/last-price.png";
import removeRedWithBgIcon from "apps/springboard/images/icons/remove.png";
import rightIcon from "apps/springboard/images/icons/right-white.png";
import startIcon from "apps/springboard/images/icons/star.png";
import startGreyIcon from "apps/springboard/images/icons/starGrey.png";

import plusBlackIcon from "apps/springboard/images/icons/add.svg";
import addDocument from "apps/springboard/images/icons/add_document.svg";
import addProductIcon from "apps/springboard/images/icons/add_new_product.svg";
import addPerson from "apps/springboard/images/icons/add_person.svg";
import filledBlackAddUser from "apps/springboard/images/icons/add_user.svg";
import plusWhiteIcon from "apps/springboard/images/icons/add_white.svg";
import downSmallArrow from "apps/springboard/images/icons/arrow_drop_down.svg";
import arrowForward from "apps/springboard/images/icons/arrow_forward.svg";
import RightArrowExlink from "apps/springboard/images/icons/arrow_right.svg";
import smallBackIcon from "apps/springboard/images/icons/back.svg";
import bannedNoteIcon from "apps/springboard/images/icons/banned-note-icon.svg";
import backIcon from "apps/springboard/images/icons/black-back.svg";
import plusFilledIcon from "apps/springboard/images/icons/black-filled-add.svg";
import rightArrowBlackFilled from "apps/springboard/images/icons/black-filled-right-arrow.svg";
import blackOutlinedCustomer from "apps/springboard/images/icons/black-outline-customer.svg";
import boltIcon from "apps/springboard/images/icons/bolt.svg";
import cartTotalIcon from "apps/springboard/images/icons/cart-with-total.svg";
import cartIcon from "apps/springboard/images/icons/cart.svg";
import cashBlackIcon from "apps/springboard/images/icons/cash-black.svg";
import cautionTriangleIcon from "apps/springboard/images/icons/caution.png";
import caution from "apps/springboard/images/icons/caution.svg";
import cautionIcon from "apps/springboard/images/icons/caution_icon.svg";
import changeIcon from "apps/springboard/images/icons/change.svg";
import checkSmall from "apps/springboard/images/icons/check.svg";
import checkBlack from "apps/springboard/images/icons/check_black.svg";
import closeIcon from "apps/springboard/images/icons/close.svg";
import blackCloseIcon from "apps/springboard/images/icons/close_black.svg";
import closeGray from "apps/springboard/images/icons/close_gray.svg";
import collapseBlack from "apps/springboard/images/icons/collapse.svg";
import creditHold from "apps/springboard/images/icons/credit_hold.svg";
import crossDark from "apps/springboard/images/icons/cross_dark.svg";
import dangerIcon from "apps/springboard/images/icons/danger.svg";
import defaultIcon from "apps/springboard/images/icons/default.svg";
import deleteDarkGrayIcon from "apps/springboard/images/icons/delete-dark-gray.svg";
import deleteHoverDarkGrayIcon from "apps/springboard/images/icons/delete-hover.svg";
import orangeDeleteIcon from "apps/springboard/images/icons/delete-orange.svg";
import bannerImage from "apps/springboard/images/icons/document.png";
import upDownArrow from "apps/springboard/images/icons/down-up.png";
import editIcon from "apps/springboard/images/icons/edit_black_pen.svg";
import noOrdersIcon from "apps/springboard/images/icons/emptyStateOrders.svg";
import expandBlack from "apps/springboard/images/icons/expand.svg";
import externalLink from "apps/springboard/images/icons/external-link.svg";
import groupIcon from "apps/springboard/images/icons/group.svg";
import greenPartiallyFilled from "apps/springboard/images/icons/group3300.svg";
import dollerCheck from "apps/springboard/images/icons/Group_1538.svg";
import disContinue from "apps/springboard/images/icons/Group_1539.svg";
import deactivated from "apps/springboard/images/icons/Group_1540.svg";
import outStock from "apps/springboard/images/icons/Group_1566.svg";
import discontinuedStockIcon from "apps/springboard/images/icons/group_2556.svg";
import impNoteIcon from "apps/springboard/images/icons/important-note-icon.svg";
import impNotesIcon from "apps/springboard/images/icons/important-notes-star.svg";
import linkIcon from "apps/springboard/images/icons/Link icon.svg";
import loadingIcon from "apps/springboard/images/icons/loading.svg";
import noQuoteAvailable from "apps/springboard/images/icons/no-quote-available.svg";
import noDeliveredStorage from "apps/springboard/images/icons/noDeliveredStorage.svg";
import noDraftsIcon from "apps/springboard/images/icons/noDraftsIcon.svg";
import noPendingStorage from "apps/springboard/images/icons/noPendingStorageIcon.svg";
import openInNewTabIcon from "apps/springboard/images/icons/open_in_new_tab.svg";
import orangePartiallyFilled from "apps/springboard/images/icons/orange-partially-filled.svg";
import orangePartiallyOutlined from "apps/springboard/images/icons/orange-partially-outlined.svg";
import printIcon from "apps/springboard/images/icons/pick-ticket.svg";
import plusIcon from "apps/springboard/images/icons/plusIcon.svg";
import searchProductEmptyIcon from "apps/springboard/images/icons/pos-cart-empty.svg";
import pressEnter from "apps/springboard/images/icons/press-download.svg";
import blackPrintFilled from "apps/springboard/images/icons/print-black-print-filled.svg";
import printImage from "apps/springboard/images/icons/print-icon.svg";
import rightGreenFilled from "apps/springboard/images/icons/right_green_filled.svg";
import rightWhiteIcon from "apps/springboard/images/icons/right_white_icon.svg";
import sandClockIcon from "apps/springboard/images/icons/sand-clock.png";
import searchIcon from "apps/springboard/images/icons/search.svg";
import searchBold from "apps/springboard/images/icons/searchBold.svg";
import settingIcon from "apps/springboard/images/icons/settings.svg";
import shipping from "apps/springboard/images/icons/shipping.svg";
import storageBlack from "apps/springboard/images/icons/storage-black.svg";
import storageOutlinedIcon from "apps/springboard/images/icons/storage-outline.svg";
import ticket from "apps/springboard/images/icons/ticket.svg";
import trashIcon from "apps/springboard/images/icons/trash.svg";
import trashRedOpen from "apps/springboard/images/icons/trash_red_open.svg";
import updateLogo from "apps/springboard/images/icons/update_logo.svg";
import whiteUploadIcon from "apps/springboard/images/icons/upload-white.svg";
import noDocumentIcon from "apps/springboard/images/icons/uploadDocument.svg";
import whiteNewTab from "apps/springboard/images/icons/white-new-tab.svg";
import whiteTruck from "apps/springboard/images/icons/white-truck.svg";
import amex from "Image/amex.svg";
import backIcons from "Image/back.svg";
import cancelCircle from "Image/cancelCircle.svg";
import cardIcon from "Image/card.svg";
import cashIcon from "Image/cash.svg";
import changePass from "Image/changePassword.svg";
import checkIcon from "Image/check.svg";
import checkCircleIcon from "Image/check_circle_black.svg";
import checkBlackReturn from "Image/checkBlack.svg";
import deActivateUser from "Image/deActivate.svg";
import deleteAccount from "Image/deleteAccount.svg";
import deleteOpen from "Image/deleteOpen.svg";
import discover from "Image/discover.svg";
import generic from "Image/generic.svg";
import loading from "Image/loading _icon.svg";
import lockManage from "Image/lock-manage.svg";
import lockAccount from "Image/lockAccount.svg";
import lock from "Image/lockIcon.svg";
import mastercard from "Image/mastercard.svg";
import newPassword from "Image/newPass.svg";
import tipCloseIcon from "Image/no_tips_found.svg";
import noReturn from "Image/noReturn.svg";
import noReturnItem from "Image/noReturnItem.svg";
import payLaterIcon from "Image/pay_later.svg";
import paypal from "Image/paypal.svg";
import print from "Image/print.svg";
import returnSearch from "Image/return-search.svg";
import rightArrow from "Image/rightArrow.svg";
import rightArrowBlack from "Image/rightArrowBlack.svg";
import resendEmail from "Image/sendMail.svg";
import storeCreditIcon from "Image/store_credit.svg";
import needHelp from "Image/support_people.svg";
import tipOpenIcon from "Image/tip_icon_1.svg";
import tipCollapseIcon from "Image/tipCollapse.svg";
import tipExpandIcon from "Image/tipExpand.svg";
import tipFooterIcon from "Image/tipFooter.svg";
import tipOffIcon from "Image/tipOff.svg";
import tipOnIcon from "Image/tipsOn.svg";
import unlockAccount from "Image/unlockAccount.svg";
import upload from "Image/upload.svg";
import visa from "Image/visa.svg";
import visibility from "Image/visibility.svg";
import visibilityGray from "Image/visibility_gray.svg";

import editPencil from "apps/springboard/images/icons/edit-pencil.svg";
import greenCircleRightIcon from "apps/springboard/images/icons/green-circle-right.svg";
import noShipmentsFound from "Image/no_shipments_found.svg";
import searchFilterIcon from "Image/search.svg";
import spinner from "Image/spinner.svg";
import noDocument from "Image/vector.svg";

const images = {
  spinner,
  noShipmentsFound,
  loadingIcon,
  downloadIcon,
  filterIcon,
  rightIcon,
  defaultIcon,
  backIcon,
  changeIcon,
  boltIcon,
  lastPriceIcon,
  cautionIcon,
  closeIcon,
  groupIcon,
  checkBlack,
  checkSmall,
  downSmallArrow,
  startIcon,
  startGreyIcon,
  dollerCheck,
  outStock,
  disContinue,
  deactivated,
  plusIcon,
  searchIcon,
  pressEnter,
  ticket,
  trashRedOpen,
  trashIcon,
  creditHold,
  addPerson,
  crossDark,
  check,
  caution,
  dangerIcon,
  impNotesIcon,
  upload,
  noReturn,
  print,
  checkBlackReturn,
  cancelCircle,
  resendEmail,
  addProductIcon,
  cartIcon,
  lock,
  changePass,
  deleteAccount,
  rightArrow,
  rightArrowBlack,
  deActivateUser,
  newPassword,
  needHelp,
  visibility,
  visibilityGray,
  tipOpenIcon,
  tipCloseIcon,
  tipOnIcon,
  tipOffIcon,
  tipCollapseIcon,
  tipExpandIcon,
  tipFooterIcon,
  bannedNoteIcon,
  impNoteIcon,
  printIcon,
  cartTotalIcon,
  shipping,
  whiteUploadIcon,
  smallBackIcon,
  settingIcon,
  searchProductEmptyIcon,
  discontinuedStockIcon,
  editIcon,
  orangeDeleteIcon,
  whiteNewTab,
  bannerImage,
  blackCloseIcon,
  rightWhiteIcon,
  plusWhiteIcon,
  removeRedWithBgIcon,
  deleteDarkGrayIcon,
  deleteHoverDarkGrayIcon,
  openInNewTabIcon,
  rightGreenFilled,
  printImage,
  backIcons,
  lockAccount,
  unlockAccount,
  lockManage,
  returnSearch,
  noReturnItem,
  loading,
  noDocumentIcon,
  orangePartiallyOutlined,
  plusFilledIcon,
  orangePartiallyFilled,
  rightArrowBlackFilled,
  blackPrintFilled,
  upDownArrow,
  cautionTriangleIcon,
  greenPartiallyFilled,
  searchBold,
  externalLink,
  blackOutlinedCustomer,
  storageOutlinedIcon,
  sandClockIcon,
  filledBlackAddUser,
  plusBlackIcon,
  cashBlackIcon,
  collapseBlack,
  expandBlack,
  storageBlack,
  whiteTruck,
  updateLogo,
  closeGray,
  addDocument,
  noDraftsIcon,
  noPendingStorage,
  noDeliveredStorage,
  noOrdersIcon,
  linkIcon,
  visa,
  amex,
  mastercard,
  paypal,
  generic,
  discover,
  searchFilterIcon,
  editPencil,
  greenCircleRightIcon,
  cardIcon,
  cashIcon,
  storeCreditIcon,
  checkCircleIcon,
  payLaterIcon,
  checkIcon,
  noQuoteAvailable,
  arrowForward,
  deleteOpen,
  noDocument,
  RightArrowExlink
};

export default images;
