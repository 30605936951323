import { Grid } from "@material-ui/core";

interface IReportDetail {
    label: string,
    primaryText: string,
    secondaryText?: string,
    primaryClass?: string,
    secondaryClass?: string,
    className?: string
}
const ReportDetail = ({ label, primaryText, secondaryText, primaryClass = "p2 text-primary", secondaryClass = "p3 text-secondary", className = undefined }: IReportDetail) => (
    <Grid container className={className ? className + ` report-div` : `report-div`}>
        <Grid item xs={12} sm={6}>
            <div className="text-secondary">
                <span className="p2">{label}</span>
            </div>
        </Grid>
        <Grid item xs={12} sm={6}>
            <div className="d-flex flex-column gap-1 ml-2">
                <span className={`${primaryText ? primaryClass : "p2 text-tertiary"}`}>{primaryText ? primaryText : "-"}</span>
                {secondaryText && <span className={secondaryClass}>{secondaryText}</span>}
            </div>
        </Grid>
    </Grid>
);

export default ReportDetail;