import { Divider } from "@material-ui/core"
import { ChargesLine } from "../ChargesLine/ChargesLine";
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";
import { formatCurrency } from "utils/numberFormat";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { CHARGES_CONFIG, CHARGES_INFO_CONFIG } from "apps/orderdetail/helper/commonConstants";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton"


const HandleLoading = (({ loading, children, height, width, borderRadius = 4 }) => {
  return (
    <>
      {loading ?
        <ShapedSkeleton
          width={width}
          height={height}
          shape={`0 0 ${width} ${height}`}
          radius={borderRadius}
          fullWidth={false}
        /> : children}
    </>
  )
})

export const TotalCalculationBlock = ({
  orderId,
  changeOrderMarginPercent,
  initialMarginPercent,
  profitData,
  loading
}) => {

  const distributor = useSelector(distributorData);
  const { httpsUrl } = distributor;
  const addCostUrl = `${httpsUrl}/manager//mOrder_GMShipFeeList.aspx?orderID=${orderId}`;
  const changeInitialMarginPercentageUrl = `${httpsUrl}/manager/mOrder_SetInitialMarginPercent?orderID=${orderId}`;
  const addExtraFee = `${httpsUrl}/manager/mOrder_AddExtraFee.aspx?orderID=${orderId}`;

  const { profit, margin } = profitData || {};

  const chargesData = CHARGES_CONFIG.map(config => ({
    label: config.label,
    value: profitData?.[config.key],
    display: config.display
  }));

  const chargesInfo = CHARGES_INFO_CONFIG.map(config => ({
    label: config.label,
    value: profitData?.[config.key],
    display: config.display
  }));

  return (
    <div className="order-total-container">
      <div className="flex-gap-3 card">
        <div className="d-flex flex-column mt-1">
          <p className="subtitle3 text-secondary">Profit</p>
          <div className="d-flex">
            <HandleLoading loading={loading} width={"170"} height={30}>
              <p className={`h1 text-primary ${profit < 0 ? "nagative-text-red" : ""}`}>{formatCurrency(profit)}</p>
            </HandleLoading>
          </div>
          <ul className="d-flex gap-2 w-100">
            <div className="d-flex flex-column gap-2 w-50">
              <p className="subtitle3 text-secondary">{"Initial Margin"}</p>
              <HandleLoading loading={loading} width={"186"} height={23}>
                <p className="h3 text-primary">{initialMarginPercent}%</p>
              </HandleLoading>
            </div>
            <div className="d-flex flex-column gap-2 w-50">
              <HandleLoading loading={loading} width={"186"} height={15}>
                <p className="subtitle3 text-secondary">{"Changed Margin"}</p>
              </HandleLoading>
              <HandleLoading loading={loading} width={"186"} height={23}>
                <p className="h3 text-primary">{changeOrderMarginPercent}%</p>
              </HandleLoading>
            </div>
          </ul>
          <ul className={`d-flex gap-2 w-100 ${!initialMarginPercent && "reverse-row"}`}>
            <div className="d-flex flex-column gap-2 w-50">
              <HandleLoading loading={loading} width={"186"} height={38} borderRadius={12}>
                <a href={addCostUrl} target="_blank" className="btn od-btn-secondary">
                  Add Cost
                </a>
              </HandleLoading>
            </div>
            <div className="d-flex flex-column gap-2 w-50 whitespace-nowrap">
              <HandleLoading loading={loading} width={"186"} height={38} borderRadius={12}>
                <a className="btn od-btn-secondary" href={changeInitialMarginPercentageUrl}>
                  {initialMarginPercent ? "Change Margin" : "Add Initial Margin"}
                </a>
              </HandleLoading>
            </div>
          </ul>
        </div>
        <Divider className="divider-opacity" />
        <ul className="d-flex flex-column">
          {chargesData.map(({ label, value, display }) => (
            <ChargesLine key={label} label={label} value={value} display={display} isCurrency={true} loading={loading} />
          ))}
          <ChargesLine key={"Margin"} label={"Margin"} value={`${margin}%`} display={true} loading={loading} />
          <div className="extra-charge my-6" >
            <Divider className="divider-opacity" style={{ flexGrow: 1 }} />
            <a className="extra-charge-btn" href={addExtraFee} target="_blank">
              <MaterialIcon icon="add" size="16" />
              Add Extra Fee
            </a>
          </div>
          {chargesInfo.map(({ label, value, display }) => (
            <ChargesLine key={label} label={label} value={value} display={display} isCurrency={true} loading={loading} />
          ))}
        </ul>
      </div >
    </div >
  );
};