import { useQuery } from "@tanstack/react-query";
import { getInvoices } from "./service";

export const useGetInvoices = (payload) => {
    const { isError, isLoading, isSuccess, data } = useQuery(
        ["get-invoices", payload],
        async () => {
            return getInvoices(payload);
        },
        {
            retry: false,
        }
    );

    return {
        isError,
        isLoading,
        isSuccess,
        data: data?.[0] || [],
    };
};