import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateShowQRCode } from "./service";

export const useUpdateShowQRCode = () => {
    const { mutateAsync, isLoading, data, error, isSuccess } = useMutation(
        async (payload) => {
            return updateShowQRCode(payload);
        },
    );

    const updateShowQR = useCallback(
        async (data) => {
            await mutateAsync(data);
        },
        [mutateAsync]
    );

    return {
        updateShowQR,
        isLoading,
        data,
        error,
        isSuccess,
    };
};