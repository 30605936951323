import React from 'react'
import "./OrderStatus.scss"
import { orderStatusStyles, orderStatusValue } from "apps/orderdetail/helper/commonConstants";
import Tooltip from '../Tooltip';

const getOrderStatusStyles = (statusKey) => orderStatusStyles[statusKey] || { color: "initial", className: "", tooltipMessage: "" };

const OrderStatus = ({ orderStatusName }) => {
    const { color, className, tooltipMessage } = getOrderStatusStyles(orderStatusName);
    const inProgressOrder = orderStatusName?.slice(0, 10);
    const finalOrderStatus = (orderStatusName === orderStatusValue.inProcess ? inProgressOrder : orderStatusName);

    return (
        <Tooltip title={tooltipMessage}>
            <p
                className="order-status"
                style={{ backgroundColor: color }}
            >
                <span className={className}>
                    {finalOrderStatus}
                </span>
            </p>
        </Tooltip>
    )
}

export default OrderStatus