import { formatCurrency } from "utils/numberFormat";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton"

const HandleLoading = (({ loading, children, height, width, borderRadius = 4 }) => {
  return (
    <>
      {loading ?
        <ShapedSkeleton
          width={width}
          height={height}
          shape={`0 0 ${width} ${height}`}
          radius={borderRadius}
          fullWidth={false}
        /> : children}
    </>
  )
})


export const ChargesLine = (props) => {
  const { label, value, display, isCurrency = false, loading } = props;
  const displayValue = isCurrency ? formatCurrency(value) : value;

  if (display || (value > 0)) {
    return (
      <div>
        <li
          className={`d-flex justify-content-between align-items-center py-1 px-2 mx-n2 rounded-lg ${!value && !loading ? "text-tertiary" : ""
            }`}
        >
          <p className="p2 d-flex flex-gap-2-items-center">{label}</p>
          <HandleLoading loading={loading} width={"120"} height={17}>
            <p className="subtitle2">{displayValue}</p>
          </HandleLoading>
        </li>
      </div>
    );
  }

  return null;
};
