import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

interface Props {
  label?: string;
  value: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customerDetails?: boolean;
  orderDetails?: boolean;
  tabIndex?: number;
  id?: string;
  disable?: boolean
}

const PosToggle = ({
  label,
  value,
  onChange,
  customerDetails,
  orderDetails,
  tabIndex,
  id,
  disable
}: Props) => {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      checked={value}
      onChange={onChange}
      tabIndex={tabIndex}
      id={id}
      disabled={disable}
    />
  ))(({ theme }) => ({
    width: 48,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(21px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#2ECA45"
              : customerDetails
                ? "#000000"
                : orderDetails ? "#027BFF" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      // '&.Mui-focusVisible .MuiSwitch-thumb': {
      // The color adjustment for customerDetails is causing a black dot inside the toggle when focused,
      // and the border styling seems unnecessary. Commenting these lines to resolve the visibility issue.
      // color: customerDetails ? "#000000" : '#33cf4d',
      // border: '6px solid #fff',
      // },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 20,
      height: 20,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <FormGroup>
      <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label={label} />
    </FormGroup>
  );
};

export default PosToggle;
