import CustomerOverview from "./customerOverview";

const CustomerDetailsGroup = (props) => {
  const { label, name, company, email, phone, address, isCustomer = false, ordererId, orderId, custCompanyID, isSendEmail, partialShipFlag = false } = props;
  return (
    <>
      {isCustomer && custCompanyID ?
        <CustomerOverview ordererId={ordererId} orderId={orderId} name={name} label={label} company={company} custCompanyID={custCompanyID} isSendEmail={isSendEmail} partialShipFlag={partialShipFlag} />
        :
        (
          <div className="d-flex flex-column">
            {label ? <p className="subtitle3 text-primary">{label}</p> : null}
            {name ? (
              <div className="d-flex align-items-center gap-3 text-primary">
                <p className="subtitle1 truncate whitespace-normal word-break">{name}</p>
              </div>
            ) : null}
            {company ? <p className="p2 text-primary whitespace-normal word-break">{company}</p> : null}
          </div>
        )
      }

      <div className="d-flex flex-column text-secondary">
        {email ? <p className="p2 truncate transform-lowercase whitespace-normal word-break">{email}</p> : null}
        {phone ? <p className="p2">{phone}</p> : null}
      </div>

      {address ? (
        <p className="p2 text-secondary whitespace-pre-line">{address}</p>
      ) : null}
    </>
  );
};

export default CustomerDetailsGroup;
