const POS_API_PATH = "pos";

export const endpoints = {
  newOrderDetails: (payload) => `orders/${payload.orderId}`,
  salesPerson: `${POS_API_PATH}/salespersons`,
  takers: `${POS_API_PATH}/takers`,
  draftsDetails: (payload) => `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/drafts?page=${payload.page}&size=${payload.size}&sort=${payload.sort}`,
  draftJobName: `${POS_API_PATH}/drafts/by/jobname/customercode/salespersoname/takername`,
  movingAvgCost: (payload) => `stock/warehouses/${payload.stockWareHouseId}/products/${payload.productId}/cost/avg/moving?startDate=${payload.startDate}&endDate=${payload.endDate}&page=${payload.page}&size=${payload.size}`,
  updateMargin: (payload) => `orders/${payload.orderID}/margin/percent/initial`,
  updateProjectName: (payload) => `orders/${payload.orderID}/project/manager`,
  profileDetails: (id) => `orders/${id}/profit`,
  orderDocument: (payload) => `docs/orders/${payload}/quote`,
  customerOverview: (custCompanyId) => `orders/customers/${custCompanyId}/overview`
};
