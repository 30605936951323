import { InputAdornment } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PosHelmet, PosInput, PosTooltip } from "apps/pos/Common/uiCore";
import ErrorBoundary from "apps/springboard/Component/ErrorBoundary";
import { EstimatorForm } from "apps/springboard/Component/EstimatorForm";
import { ExpirationDateForm } from "apps/springboard/Component/ExpirationDateForm";
import { LikelihoodOfCloseForm } from "apps/springboard/Component/LikelihoodOfCloseForm";
import { LinkQuoteRecipientForm } from "apps/springboard/Component/LinkQuoteRecipientForm";
import QuoteMetadata from "apps/springboard/Component/QuoteMetadata";
import QuoteOrders from "apps/springboard/Component/QuoteOrders";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import SBSelect from "apps/springboard/Component/SBSelect";
import { SalespersonForm } from "apps/springboard/Component/SalespersonForm";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import { QuoteDocuments } from "apps/springboard/QuoteDocuments/QuoteDocuments";
import QuoteItems from "apps/springboard/QuoteItems/QuoteItems";
import { QuoteMessages } from "apps/springboard/QuoteMessages/QuoteMessages";
import NotesDialog from "apps/springboard/QuoteNote/NotesDialog/NotesDialog";
import { QuoteReps } from "apps/springboard/QuoteReps/QuoteReps";
import { QuoteRfi } from "apps/springboard/QuoteRfi/QuoteRfi";
import QuoteSearch from "apps/springboard/QuoteSearch/QuoteSearch";
import * as Styled from "apps/springboard/QuoteStyleComponent/QuoteStyledComponent";
import { TabNavigation } from "components";
import Button from "components/SPButton/Button";
import { useVerifyDueDate } from "services/quoteDetail/hooks/useVerifyDueDate";
import { OpenQuoteItem, QuoteNote } from "../";

import { QuoteDetailItem, QuoteListItem, SalesPersonItem } from "models";

import closeIcon from "apps/springboard/images/icons/close_black.svg";

import texts from "apps/springboard/locales";
import {
  colors,
  dateType,
  defaultSelectedMainTab,
  springBoardText,
  validStatusValues,
} from "constants/constant";
import { SearchIcon } from "constants/icons";
import images from "constants/images";
import { Paths } from "enums";
import {
  PriceRecdStatus,
  QuoteLabelStatus,
  QuoteStatusTab,
  TicketStatus,
} from "utils/data";
import { keys } from "utils/helper";
import { authorizationToken, setDateWithFormat } from "utils/utils";
import { formatBuyPriceText } from "../Component/SBItems/Items/ItemGroupHeader/utils";

import {
  getActiveSalesPerson,
  getCustomerQuoteInfo,
  getLikelyHoodComment,
  getParentQuote,
  getQuoteDetail,
  getQuotesByStatus,
  getTicketTarget,
  updateBuyPriceReceivedFromRepFlag,
  verifyForChildQuotes,
} from "services";
import { useRemoveEstimator } from "services/quoteDetail/hooks/useRemoveEstimator";

import { UpdateModalContent } from "sagas/modal/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import {
  getResetAllTabsData,
  getVersionHistoryRequest,
  selectedQuoteId,
  setDocUploadModal,
  setLikelyHoodComment,
  setPackageInfo,
  setParentQuote,
  setQuoteDetail,
  setSalesPersonData,
  setSubQuoteList,
} from "sagas/quoteDetail/actions";
import {
  customerQuoteData,
  getLikelyHoodCommentSelector,
  getOpenMiniNoteValue,
  getQuoteJobHighlights,
  getQuoteListItem,
  getQuoteMajorReps,
  getSalesPersonData,
  getTotalQuotes,
  getVersionHistory,
  quoteIdDetail,
} from "sagas/quoteDetail/selector";
import { setQuoteList } from "sagas/quoteList/actions";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import HeaderSelection from "components/HeaderSelection/HeaderSelection";
import IconWithTooltip from "components/IconWithTooltip/IconWithTooltip";
import { OpenSubQuote } from "components/OpenSubQuote/OpenSubQuote";
import dayjs from "dayjs";
import { distributorData } from "sagas/distributor/selector";
import { setOpenMiniNote } from "sagas/quoteDetail/quoteDetailsSlice";
import { useGetSubQuotes } from "services/quoteDetail/hooks/useGetSubQuotes";
import "styles/admin/springboard/quoteDetails.scss";
import { dateIsAfter, dateIsSame } from "utils/dayjsFunc";
import { ChangeQuoteStatusForm } from "../Component/ChangeQuoteStatusForm";
import { DueDateForm } from "../Component/DueDateForm";

function ConfirmationModal(props) {
  const dispatch = useDispatch();
  const { onSuccess, currentStatus, newStatus } = props;
  const onSubmit = () => {
    onSuccess();
  };

  return (
    <Fragment>
      <div className="ml-4">
        <h3>Confirmation</h3>
      </div>

      <h6>Are you sure you want to change quote status?</h6>
      <span>
        {currentStatus} to {newStatus}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div className="edit_cust_btn">
          <div className="border-bottom-lightgray border-bottom border-md"></div>
          <Button
            label="No"
            type="secondary"
            onClick={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: false,
                  removePaddingBottom: false,
                  noMaxWidth: false,
                })
              );
            }}
          />
          <Button label="Yes" type="primary" onClick={onSubmit} />
        </div>
      </div>
    </Fragment>
  );
}

export function QuoteDetail() {
  const dispatch = useDispatch();

  const salesPersonData = useSelector(getSalesPersonData)
  const likelyHoodComment = useSelector(getLikelyHoodCommentSelector)
  const quoteMajorReps = useSelector(getQuoteMajorReps)
  const quoteJobHighlights = useSelector(getQuoteJobHighlights);
  const { removeEstimatorFn, isLoading, isSuccess } = useRemoveEstimator();
  const distributor = useSelector(distributorData)

  const quoteListItem = useSelector(getQuoteListItem);
  const totalQuotes = useSelector(getTotalQuotes);
  const versionHistory = useSelector(getVersionHistory);
  const quoteDetail = useSelector(quoteIdDetail);
  const packageInfoData = useSelector(customerQuoteData);

  const { companyName: customerCompanyName } = packageInfoData || {};

  const navigate = useNavigate();

  const { noQuoteAvailable } = images;
  const { httpsUrl } = distributor || {};
  const {
    dueOnLabel,
    closeDateLabel,
    priceLabel,
    quoteToLabel,
    estimatorLabel,
    salesPersonLabel,
    likeHoodOfCloseLabel,
    dueDateLabel,
    reviseDueDate,
    newCloseDate,
    changeCloseDate,
    newDueDate,
    tickets,
    jobHighlights,
    majorReps,
    zero,
    one,
    notSet,
    status
  } = springBoardText;
  const { ViewRevisionHistory, switchToOldDesign } = texts;
  const windowsUrl = window.location.pathname;

  const [selectedQuote, setSelectedQuote] = useState<QuoteDetailItem>();
  const [ticketCount, setTicketCount] = useState(0);
  const [quoteData, setQuoteData] = useState<QuoteListItem[]>([]);
  const [salesPerson, setSalesPerson] = useState<SalesPersonItem[]>();
  const [headerCollapse, setHeaderCollapse] = useState(false);
  const [quoteSearchVis, setQuoteSearchVis] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedMainTab, setSelectedMainTab] = useState(1);
  const [sellerID, setSellerID] = useState(null);
  const [statusLoading, setStatusLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [doesHaveMore, setHaveMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isCustomInfo, setIsCustomInfo] = useState(false);
  const [isEstimator, setIsEstimator] = useState(false);
  const [estimator, setEstimator] = useState("");
  const [isExpirationDate, setIsExpirationDate] = useState(false);
  const [isCloseDate, setIsCloseDate] = useState(false);
  const [selectedQuoteByValue, setSelectedQuoteByValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [noteCategory, setNoteCategory] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [hasSubQuotes, setHasSubQuotes] = useState(false);
  const { DefaultLoginId } = authorizationToken("Authorization") || {};
  const [isLikelihoodOfClose, setIsLikelihoodOfClose] = useState(false);
  const [isSalesperson, setIsSalesperson] = useState(false);
  const [isChangeQuoteStatus, setIsChangeQuoteStatus] = useState(false);
  const [isDueDate, setIsDueDate] = useState(false);
  const [loading, setLoading] = useState({
    dueDate: false,
    closeDate: false,
    expireDate: false,
  });
  const {
    closeDate,
    revisedDueDate,
    jobQuoteID,
    jobQuoteTitle,
    jobLotPackageID,
    buyPriceReceivedFromRepFlag,
    creatorID,
    creatorName,
    jobQuoteStatusName,
    dueDate,
    jobQuotePrice,
    expDate,
    likelihoodOfClose,
    assignDateToEstimator,
    salespersonID,
    copiedFromQuoteID,
  } = selectedQuote || {};
  const finalDueData = revisedDueDate ?? dueDate;
  const { isEditDueDate, setVerifyDueDate } = useVerifyDueDate();
  const { data: dueDateVerifyFlag } = isEditDueDate || {};
  const keysToFilter = ["jobQuoteID", "customerName", "jobQuoteTitle"];
  const searchTextLowerCase = searchText.toLowerCase();
  const fileDueDateModalLabel =
    dueDateVerifyFlag && Boolean(sellerID) ? reviseDueDate : dueDateLabel;
  const { setQuoteParamsId, getSubQuoteData, isSubQuoteFetching } = useGetSubQuotes();

  const getDialogOpenValue = useSelector(getOpenMiniNoteValue);
  useEffect(() => {
    setVerifyDueDate(sellerID);
  }, [sellerID]);

  const expirationStatusLabel = dayjs().isAfter(expDate)
    ? "EXPIRED ON"
    : "EXPIRES ON";

  const priceValue = `$${jobQuotePrice
    ?.toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;

  const quotedToValue = customerCompanyName
    ? customerCompanyName.length > 20
      ? customerCompanyName.substr(0, 19) + ".."
      : customerCompanyName
    : notSet;

  const estimatorValue = estimator
    ? estimator.length > 12
      ? estimator.substr(0, 11) + ".."
      : estimator
    : notSet;

  const handleUpdateModalContent = () => {
    dispatch(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
        noMaxWidth: false,
      })
    );
  };

  const handleCommonSuccess = ({ text }) => {
    dispatch(snakbarShow({ message: text }));
    setSelectedQuote(null);
    selectQuote(jobQuoteID, jobLotPackageID, false, true);
    handleUpdateModalContent();
  };

  const handleKeyPress = (e) => {
    const currentUrl = window.location.href;
    const lastIndex = currentUrl.lastIndexOf("/");
    const jobId = Number(currentUrl.substring(lastIndex + 1));

    if (getDialogOpenValue && e.keyCode !== keys.esc) {
      return;
    }

    switch (e.keyCode) {
      case keys.t:
        const dropdownButton = document.getElementById(
          "dropdownMenuButtonForTickets"
        );
        if (dropdownButton) {
          dropdownButton.click();
        }
        break;

      case keys.l:
        setIsLikelihoodOfClose(true);
        break;

      case keys.k:
        dispatch(
          UpdateModalContent({
            isOpen: true,
            children: (
              <ConfirmationModal
                currentStatus={QuoteLabelStatus[status]}
                newStatus={"Kill"}
                onSuccess={handleUpdateModalContent}
              />
            ),
            fixedModal: false,
            removePaddingBottom: false,
            noMaxWidth: false,
            mediumHeight: true,
          })
        );
        break;

      case keys.m:
      case keys.p:
      case keys.u:
      case keys.s:
        if (!isNaN(jobId)) {
          let path = "";
          switch (e.keyCode) {
            case keys.m:
              path = `/gmSale/msJobLotQuote_send.aspx?jobLotQuoteID=${jobId}`;
              break;
            case keys.p:
              path = `/gmSale/msJobLotQuote_previewPDF?jobLotQuoteID=${jobId}`;
              break;
            case keys.u:
              path = `/gmSale/msJobLotQuote_preview?jobLotQuoteID=${jobId}`;
              break;
            case keys.s:
              path = `/gmSale/msJobLotQuote_Add_subQuote?parentJobLotQuoteID=${jobId}`;
              break;
          }
          openLink(`${distributor?.httpsUrl}${path}`);
        }
        break;

      case keys.d:
        if (!isNaN(jobId)) {
          openLink(
            `${distributor?.httpsUrl}/gmSale/msJobLotQuote_repDocUpload.aspx?quotePackageID=${jobLotPackageID}&jobLotQuoteID=${jobQuoteID}`
          );
        }
        break;

      case keys.numKey1:
      case keys.numKey2:
      case keys.numKey3:
      case keys.numKey4:
      case keys.numKey5:
      case keys.numKey6:
      case keys.numKey7:
      case keys.numKey8:
        if (!isNaN(jobId)) {
          const tabNumber = e.keyCode - keys.numKey1;
          setSelectedTab(tabNumber);
        }
        break;

      default:
        // Handle any keys that are not defined
        break;
    }
  };

  useEffect(() => {
    document?.addEventListener("keydown", handleKeyPress);
    return () => {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, [getDialogOpenValue]);

  useEffect(() => {
    if (isSuccess) {
      setEstimator("");
      dispatch(
        UpdateModalContent({
          children: null,
          isOpen: false,
          fixedModal: false,
          removePaddingBottom: false,
          noMaxWidth: false,
        })
      );
      handleCloseModel();
      dispatch(snakbarShow({ message: texts.estimatorRemoved }));
    }
  }, [isSuccess]);

  const handleScroll = () => {
    const lastScroll = filteredList?.length === totalQuotes;

    if (lastScroll) {
      setHaveMore(false);
    }
    const updatedPage = page + 1;
    setPage(updatedPage);
    if (filteredList?.length < totalQuotes) {
      getQuotesByStatus(
        selectedQuoteByValue,
        selectedMainTab,
        updatedPage,
        20
      ).then((res) => {
        dispatch(setQuoteList(res));
      });
    }
  };

  const filteredList = quoteData.filter((obj) =>
    keysToFilter.some((key) => {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key].toString().toLowerCase();
        return value.includes(searchTextLowerCase);
      }
      return false;
    })
  );

  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;
    setSearchText(value);
  };

  useEffect(() => {
    if (!salesPerson?.length) {
      getActiveSalesPerson().then((res) => {
        dispatch(setSalesPersonData(res?.data));
      });
    }
    fetchMyAPI(selectedQuoteByValue);
    getSellerId();
  }, []);

  const fetchMyAPI = (salesPersonId, changePath = false) => {
    if (changePath) {
      navigate(Paths.quoteDetail);
      setSelectedQuote(null);
    }
    setQuoteData([]);
    setStatusLoading(true);
    setHaveMore(true);
    getQuotesByStatus(salesPersonId, selectedMainTab, 1, 20).then((res) => {
      const tempQuoteData = res?.data; //?.slice(0,20)

      if (tempQuoteData && tempQuoteData?.length) {
        const isQuoteDetail = window.location.pathname.includes(Paths.quoteJob);
        const quoteID = window.location.pathname.split("/")[5];
        selectQuote(
          isQuoteDetail ? quoteID : tempQuoteData?.[0]?.jobLotPackageID,
          tempQuoteData?.[0]?.jobLotPackageID,
          true,
          true
        );
      } else {
        setSelectedQuote(null);
        navigate(Paths.quoteDetail);
      }
      dispatch(setQuoteList(res));
      setStatusLoading(false);
    });
  };

  useEffect(() => {
    if (jobQuoteID) {
      dispatch(getVersionHistoryRequest({ id: jobQuoteID, showAll: 0 }));
    }
  }, [jobQuoteID]);

  useEffect(() => {
    if (quoteListItem?.length) {
      setQuoteData([...quoteData, ...quoteListItem]);
    }
  }, [quoteListItem]);

  useEffect(() => {
    if (salesPersonData) {
      const data = [];
      salesPersonData.map((e) =>
        data.push({ label: e.salespersonName, value: e.personId })
      );
      setSalesPerson(data);
    }
  }, [salesPersonData]);

  const getSellerId = async () => {
    const id = await localStorage.getItem("sellerID");
    setSellerID(parseInt(id));
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const selectQuote = (
    quoteId: number,
    jobLotPackageID: number,
    isRefreshPage,
    isSubQuoteList
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    const jobLotQuoteID = urlParams.get("jobLotQuoteID");
    const ID = quoteId || Number(jobLotQuoteID);
    getQuoteDetail(ID).then((quoteDetail) => {
      if (quoteDetail) {
        setCustomerInfo(quoteDetail.jobLotPackageID);
        dispatch(setQuoteDetail(quoteDetail));
        dispatch(selectedQuoteId(quoteId));
        if (isRefreshPage && quoteDetail) {
          if (validStatusValues.includes(quoteDetail.status)) {
            onTabChange(quoteDetail?.status, true);
            setSelectedMainTab(quoteDetail?.status);
          } else {
            onTabChange(defaultSelectedMainTab, true);
            setSelectedMainTab(defaultSelectedMainTab);
          }
        }

        setSelectedQuote(quoteDetail);
        setEstimator(quoteDetail?.estimatorName);
        getLikelyHoodComment(quoteId).then((res) => {
          dispatch(setLikelyHoodComment(res?.data));
        });
        getParentQuote(quoteId).then((res) => {
          if (res?.data === 0) {
            dispatch(setParentQuote(quoteDetail));
            subQuoteData(quoteId);
          } else {
            getQuoteDetail(res?.data).then((data) => {
              dispatch(setParentQuote(data));
            });
            if (isSubQuoteList) {
              subQuoteData(quoteId); //res?.data
            }
          }
        });
      } else {
        setSelectedQuote(null);
        navigate(Paths.quoteDetail);
      }
    });
    getTicketTarget(quoteId).then((response) => {
      setTicketCount(response?.data.length || 0);
    });
    verifyForChildQuotes(quoteId).then((response) => {
      setHasSubQuotes(response?.data);
    });
  };

  const subQuoteData = (id) => {
    setQuoteParamsId(id);
  };

  const onTabChange = async (status: number, samePageNavigation?: boolean) => {
    resetAllOtherData();
    setStatusLoading(true);
    setPage(1);
    setQuoteData([]);
    setSelectedQuote(null);
    !samePageNavigation ? navigate(Paths.quoteDetail) : null;
    getQuotesByStatus(selectedQuoteByValue, status, 1, 20).then((res) => {
      setStatusLoading(false);
      dispatch(setQuoteList(res));
    });
  };

  const resetAllOtherData = () => {
    dispatch(setPackageInfo([])); // Reset the Quote To [Customer Info]
    dispatch(setQuoteDetail([])); // Reset the selected Quote Detail
    setSelectedQuote(null); // Reset the selected Quote Detail From state Variable
    dispatch(setSubQuoteList([])); // Reset the Sub-Quote List
    dispatch(getResetAllTabsData()); // Reset All the notes , Reps , Message , RFI and Orders
  };

  const searchModal = (value) => {
    setQuoteSearchVis(value);
  };

  const setCustomerInfo = (jobQuoteID) => {
    getCustomerQuoteInfo(jobQuoteID).then((res) => {
      dispatch(setPackageInfo(res));
    });
  };

  const handleChangeSuccess = (formType?: string) => {
    setLoading((prev) => ({ ...prev, [formType]: true }));
    getQuoteDetail(jobQuoteID)
      .then((res) => {
        dispatch(setQuoteDetail(res));
        setLoading({
          ...loading,
          dueDate: false,
          closeDate: false,
          expireDate: false,
        });
        setSelectedQuote(res);
        setEstimator(res.estimatorName);
      })
      .then(() => {
        handleUpdateModalContent();
      });
  };

  const selectListQuote = (jobQuoteID, jobLotPackageID) => {
    resetAllOtherData();
    setSelectedQuote(null);
    selectQuote(jobQuoteID, jobLotPackageID, false, true);
    navigate(`${Paths.quoteJob}/${jobQuoteID.toString()}`);
  };

  const determineBgColor = (status, dueDate) => {
    const today = new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const {
      borderColor,
      coralRed,
      giantsOrange,
      mikadoYellow,
      ultramarineBlue,
      UfoGreen,
    } = colors;

    const getDateColor = (due) => {
      if (dateIsAfter({ date: today, selectedDate: due })) {
        return coralRed;
      } else if (dateIsSame({ date: today, selectedDate: due })) {
        return giantsOrange;
      } else if (dateIsAfter({ date: tomorrowDate, selectedDate: due })) {
        return mikadoYellow;
      } else {
        return status === 5 ? ultramarineBlue : UfoGreen;
      }
    };
    switch (status) {
      case 1:
      case 5:
        return getDateColor(dueDate);
      case 0:
        return borderColor;
      default:
        return "";
    }
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  const quoteList = () => {
    return (
      <>
        {statusLoading ? (
          <ShapedSkeleton
            shape="0 0 60 20, 80 0 320 20, 0 224 60 20, 0 112 60 20, 0 336 60 20, 80 224 320 20, 80 112 320 20, 80 336 320 20, 80 32 200 20, 80 265 200 20, 80 144 200 20, 80 368 200 20"
            width={400}
            height={388}
            radius={4}
            fullWidth={true}
            classes="mt-4"
          />
        ) : (
          <InfiniteScroll
            className="pb-60"
            dataLength={filteredList?.length || 0}
            next={handleScroll}
            height={750}
            hasMore={doesHaveMore}
            loader={<div></div>}
            scrollThreshold={0.8}
          >
            {filteredList?.length ? (
              filteredList?.map(
                (
                  {
                    customerCompany,
                    creatorInit,
                    createDate,
                    latestSentDate,
                    dueDate,
                    expDate,
                    jobQuoteTitle,
                    status,
                    jobQuoteID,
                    jobLotPackageID,
                  },
                  key
                ) => (
                  <OpenQuoteItem
                    selectedMainTab={selectedMainTab}
                    company={customerCompany}
                    creator={creatorInit}
                    dateModified={createDate}
                    sentDate={latestSentDate}
                    dueDate={dueDate}
                    expiredDate={expDate}
                    jobName={jobQuoteTitle}
                    bgColor={determineBgColor(status, status)}
                    onClick={() => {
                      selectListQuote(jobQuoteID, jobLotPackageID);
                    }}
                    quoteId={jobQuoteID}
                    status={status}
                    key={key}
                  />
                )
              )
            ) : (
              <div className="d-flex no-quote-available-icon">
                <img src={noQuoteAvailable} alt="" />
                <p className="empty-message">
                  {`${searchText
                    ? `No ${QuoteStatusTab[selectedMainTab]} quotes found for "${searchText}" by `
                    : "No quotes available"
                    }`}
                  <SBSelect
                    salesPerson={salesPerson}
                    onSelected={onSelected}
                    selectedQuoteByValue={selectedQuoteByValue}
                  />
                </p>
              </div>
            )}
          </InfiniteScroll>
        )}
      </>
    );
  };

  const handleCloseModel = () => {
    setIsCustomInfo(false);
    setIsEstimator(false);
    setIsLikelihoodOfClose(false);
    setIsSalesperson(false);
    setIsExpirationDate(false);
    setIsDueDate(false);
    setIsCloseDate(false);
    setIsChangeQuoteStatus(false);
  };

  const onTicketStatus = (link) => {
    openLink(
      `${distributor?.httpsUrl}/tickets/msTicket_create?${link}=1&targetTypeID=1&targetID=${jobQuoteID}`
    );
  };

  const viewAllTickets = () => {
    openLink(
      `${distributor?.httpsUrl}/tickets/msTicket_by_target?targetTypeID=1&targetID=${jobQuoteID}`
    );
  };

  const createNewTicket = () => {
    openLink(
      `${distributor?.httpsUrl}/tickets/msTicket_create?targetTypeID=1&targetID=${jobQuoteID}`
    );
  };

  const onSelected = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuoteByValue(selectedValue);
    fetchMyAPI(selectedValue, true);
  };

  const openCloseNotes = () => {
    setNoteCategory("");
    setNoteValue("");
    setOpenDialog(!openDialog);
    dispatch(setOpenMiniNote(false));
  };

  const openJobHighLight = () => {
    setOpenDialog(true);
    setNoteCategory(jobHighlights);
    setNoteValue(quoteJobHighlights);
  };

  const openMajorReps = () => {
    setOpenDialog(true);
    setNoteCategory(majorReps);
    setNoteValue(quoteMajorReps);
  };

  const updateBuyPriceRcvd = () => {
    const toggleFlag = buyPriceReceivedFromRepFlag === zero ? one : zero;

    updateBuyPriceReceivedFromRepFlag(jobQuoteID, toggleFlag).then(() => {
      selectQuote(jobQuoteID, jobLotPackageID, false, false);
      const buyPriceText = formatBuyPriceText(
        !quoteDetail?.buyPriceReceivedFromRepFlag
      );
      dispatch(snakbarShow({ message: `Buy price ${buyPriceText}` }));
    });
  };

  const openSubQuoteOfQuote = (id) => {
    navigate(`${Paths.quoteJob}/${id}`);
    resetAllOtherData();
    selectQuote(id, null, false, true);
  };

  return (
    <>
      <PosHelmet
        title={
          jobQuoteID
            ? `#${jobQuoteID} ${jobQuoteTitle ?? ""} | Springboard`
            : `Springboard`
        }
        metaName="Admin page"
        content="admin page"
      />
      <ErrorBoundary>
        <section className="d-flex h-custom1">
          <Styled.OpenQuoteStyled className="pe-1 border-end inboxLeftbar">
            {quoteSearchVis ? (
              <>
                <div
                  className="d-flex align-items-center mb-4"
                  style={{ justifyContent: "space-between" }}
                >
                  <span className="fs-16 black_text">Search</span>
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-link p-0 searchBtn"
                      onClick={() => searchModal(false)}
                    >
                      <img src={closeIcon} alt="" />
                    </button>
                  </div>
                </div>
                <QuoteSearch />
              </>
            ) : (
              <>
                <div className="quotes-header-top">
                  <div className="d-flex align-items-center quotes-tab-header justify-content-between">
                    <div className="d-flex flex-align-item-center">
                      <div className="inboxLeftbarTitle">Quotes by</div>
                      <div className="salesperson-dropdown">
                        <SBSelect
                          salesPerson={salesPerson}
                          onSelected={onSelected}
                          selectedQuoteByValue={selectedQuoteByValue}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <PosTooltip title="New Job Quote" placement="top">
                        <span>
                          <IconButton
                            name="add"
                            type="primary"
                            target="_blank"
                            href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_Add`}
                            className="add-quote-button"
                          />
                        </span>
                      </PosTooltip>
                    </div>
                  </div>
                  <div className="quote-search-box">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      type="text"
                      className="document-search-input search-focus"
                      autoFocus={false}
                      placeholder={`Job name, customer, quote ID`}
                      value={searchText}
                      onChange={(e) => handleSearchChange(e)}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <TabNavigation
                  className="px-0 inboxlistsHdr"
                  tabs={[
                    {
                      id: "new",
                      label: "New",
                      selected: selectedMainTab === 1,
                      content: quoteList(),
                      onClick: () => {
                        onTabChange(1);
                        setSelectedMainTab(1);
                      },
                    },
                    {
                      id: "sent",
                      label: "Sent",
                      selected: selectedMainTab === 5,
                      content: quoteList(),
                      onClick: () => {
                        onTabChange(5);
                        setSelectedMainTab(5);
                      },
                    },
                    {
                      id: "converted",
                      label: "Converted",
                      selected: selectedMainTab === 2,
                      content: quoteList(),
                      onClick: () => {
                        onTabChange(2);
                        setSelectedMainTab(2);
                      },
                    },
                    {
                      id: "won",
                      label: "Won",
                      selected: selectedMainTab === 4,
                      content: quoteList(),
                      onClick: () => {
                        onTabChange(4);
                        setSelectedMainTab(4);
                      },
                    },
                    {
                      id: "expired",
                      label: "Expired",
                      selected: selectedMainTab === 0,
                      content: quoteList(),
                      onClick: () => {
                        onTabChange(0);
                        setSelectedMainTab(0);
                      },
                    },
                    {
                      id: "dead",
                      label: "Dead",
                      selected: selectedMainTab === 6,
                      content: quoteList(),
                      onClick: () => {
                        onTabChange(6);
                        setSelectedMainTab(6);
                      },
                    },
                  ]}
                />
              </>
            )}
          </Styled.OpenQuoteStyled>
          {selectedQuote ? (
            <Styled.QuoteDetailsStyled className="inbox-detailRightside">
              <section className="pd-top-20">
                <div className="d-flex align-items-center justify-content-between">
                  <OpenSubQuote
                    handleNavigateSubQuote={openSubQuoteOfQuote}
                    jobQuoteID={jobQuoteID}
                    getSubQuoteData={getSubQuoteData}
                    loading={isSubQuoteFetching}
                    openJobHighLight={openJobHighLight}
                    openMajorReps={openMajorReps}
                  />
                  <div className="d-flex align-items-center">
                    <Button
                      label="Edit"
                      leadingIcon="edit"
                      type="tertiary"
                      size="small"
                      href={`${httpsUrl}/gmSale/msJobLotQuote_preview?jobLotQuoteID=${jobQuoteID}`}
                      target="_blank"
                    />
                    <IconWithTooltip
                      className={
                        versionHistory.length
                          ? "quote-details-history"
                          : "quote-details-history-no"
                      }
                      link={
                        versionHistory?.length
                          ? `${httpsUrl}/gmSale/msJobLotQuoteHistory?quotePackageID=${jobLotPackageID}&jobLotQuoteID=${jobQuoteID}`
                          : null
                      }
                      name="history"
                      placement="top"
                      title={ViewRevisionHistory}
                    />
                    <IconWithTooltip
                      link={`${httpsUrl}/gmSale/msJobLotQuote_detail.aspx?jobLotQuoteID=${jobQuoteID}`}
                      name="exit_to_app"
                      placement="top"
                      title={switchToOldDesign}
                    />
                    <IconButton
                      name={headerCollapse ? "unfold_more" : "unfold_less"}
                      onClick={() => setHeaderCollapse(!headerCollapse)}
                      type="tertiary"
                      className={"quote-details-history"}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <h2 className="f28-slab-500 text-black">
                    <span className="f28-slab-500 light-grey quote-mr-10">
                      {`# ${jobQuoteID}`}
                    </span>
                    {selectedQuote.jobQuoteTitle}
                  </h2>
                </div>
              </section>
              <Collapse in={!headerCollapse} timeout={200}>
                <>
                  <div className="created-by-status-info">
                    <span className="fs-12 inter-reg mt-2 mb-4 status-date-color">
                      {copiedFromQuoteID ? (
                        <a
                          href={`${httpsUrl}/app/springboard/quotes/job/${copiedFromQuoteID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="copy-quote-link"
                        >
                          {`Copy of # ${copiedFromQuoteID}`}
                        </a>
                      ) : (
                        "Created"
                      )}
                      {` ${creatorName ? "by " + selectedQuote.creatorName : ""
                        }`}
                      {` ${selectedQuote.createDate
                        ? "on " + setDateWithFormat(selectedQuote.createDate)
                        : ""
                        }`}
                    </span>
                  </div>
                  <Styled.QuoteDetailMetaHeader className="d-inline-flex quote-main-info-header-section">
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsChangeQuoteStatus((prev) => !prev)}
                      label={status}
                      value={jobQuoteStatusName}
                      showSkeleton={statusLoading}
                    />
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsExpirationDate(true)}
                      label={expirationStatusLabel}
                      value={setDateWithFormat(expDate)}
                      showSkeleton={loading.expireDate}
                    />
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsDueDate(true)}
                      label={dueOnLabel}
                      value={
                        finalDueData ? setDateWithFormat(finalDueData) : notSet
                      }
                      textColorBlack={Boolean(finalDueData)}
                      showSkeleton={loading.dueDate}
                    />
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsCloseDate(true)}
                      label={closeDateLabel}
                      value={closeDate ? setDateWithFormat(closeDate) : notSet}
                      textColorBlack={Boolean(closeDate)}
                      showSkeleton={loading.closeDate}
                    />
                    <HeaderSelection label={priceLabel} value={priceValue} />
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsCustomInfo(true)}
                      label={quoteToLabel}
                      value={quotedToValue}
                      textColorBlack={Boolean(customerCompanyName)}
                      valueClassName='quote-to-value'
                    />
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsEstimator(true)}
                      label={estimatorLabel}
                      value={estimatorValue}
                      textColorBlack={Boolean(estimator)}
                    />
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsSalesperson(true)}
                      label={salesPersonLabel}
                      value={selectedQuote.salespersonName}
                    />
                    <button
                      className="pr-space is-clickable text-start border-0"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <p className="status-title-color fs-10 inter-reg mb-0">
                        BUY PRICE RCVD.
                      </p>
                      <div className="dropdown">
                        <span className="fw-medium fs-14 inter-med text-black  p-0 dropdown-toggle inter-med">
                          {buyPriceReceivedFromRepFlag ? "Yes" : "No"}
                        </span>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu selectList"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {PriceRecdStatus.map((option, index) => {
                        // Check if buyPriceReceivedFromRepFlag is 0 or 1
                        const flag =
                          buyPriceReceivedFromRepFlag === zero ? one : zero;
                        return (
                          // Only render the option if it matches the flag
                          flag === index && (
                            <Styled.DropDownItem
                              className="fs-14 inter-reg p-2"
                              onClick={updateBuyPriceRcvd}
                              key={index}
                            >
                              {option}
                            </Styled.DropDownItem>
                          )
                        );
                      })}
                    </div>
                    <HeaderSelection
                      isEdit
                      onClick={() => setIsLikelihoodOfClose(true)}
                      label={likeHoodOfCloseLabel}
                      value={`${likelihoodOfClose}%`}
                    />
                    <button
                      className="pr-space is-clickable border-0"
                      type="button"
                      id="dropdownMenuButtonForTickets"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <p className="status-title-color fs-10 inter-reg mb-0">
                        {tickets}
                      </p>
                      <div className="dropdown">
                        <span className="fw-medium fs-14 text-black  p-0 dropdown-toggle inter-med">
                          {ticketCount ? (
                            <>
                              {ticketCount} ticket{ticketCount === 1 ? "" : "s"}
                            </>
                          ) : (
                            "None"
                          )}
                        </span>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu selectList tickets-list-modal"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        className="ticket-button icon-visible"
                        onClick={createNewTicket}
                      >
                        {"Create a new ticket"}
                        <MaterialIcon icon="add" classes="icon" />
                      </button>
                      <button
                        className="ticket-button"
                        onClick={viewAllTickets}
                      >
                        {"View all tickets"}
                        <MaterialIcon icon="arrow_forward" classes="icon" />
                      </button>

                      <div className="fs-10 status-title-color py-2 border-grey-top">
                        START WITH A TEMPLATE
                      </div>
                      {TicketStatus.map((option) => {
                        const { title, link } = option;
                        return (
                          <button
                            className="ticket-button"
                            onClick={() => onTicketStatus(link)}
                            key={title}
                          >
                            {title}
                            <MaterialIcon icon="arrow_forward" classes="icon" />
                          </button>
                        );
                      })}
                    </div>
                  </Styled.QuoteDetailMetaHeader>
                </>
              </Collapse>
              <TabNavigation
                className="mb-2 px-0 inboxlistsHdr"
                tabs={[
                  {
                    id: "reps",
                    label: "Reps",
                    selected: selectedTab === 0,
                    content: (
                      <QuoteReps
                        quoteId={jobQuoteID}
                        goToDocumentTab={() => setSelectedTab(3)}
                        goToUploadDocument={() => {
                          setSelectedTab(3);
                          dispatch(setDocUploadModal(true));
                        }}
                        jobLotPackageId={jobLotPackageID}
                      />
                    ),
                    onClick: () => {
                      setSelectedTab(0);
                      dispatch(setDocUploadModal(false));
                    },
                  },
                  {
                    id: "items",
                    label: "Items",
                    selected: selectedTab === 1,
                    content: (
                      <QuoteItems
                        quoteData={selectedQuote}
                        buyPriceReceivedFromRepFlag={
                          quoteDetail?.buyPriceReceivedFromRepFlag
                        }
                        goToDocumentTab={() => setSelectedTab(3)}
                      />
                    ),
                    onClick: () => setSelectedTab(1),
                  },
                  {
                    id: "notes",
                    label: "Notes",
                    selected: selectedTab === 2,
                    content: <QuoteNote quoteId={jobQuoteID} />,
                    onClick: () => setSelectedTab(2),
                  },
                  {
                    id: "documents",
                    label: "Documents",
                    selected: selectedTab === 3,
                    content: <QuoteDocuments quoteId={jobQuoteID} />,
                    onClick: () => setSelectedTab(3),
                  },
                  //Add below tab in handleKeyPress function if needed in future and change tab sequence
                  // Might be need later
                  //{
                  //   id: "activity",
                  //   label: "Activity",
                  //   selected: selectedTab === 4,
                  //   content: (
                  //     <QuoteActivity quoteId={jobQuoteID} />
                  //   ),
                  //   onClick: () => setSelectedTab(4),
                  // },
                  {
                    id: "messages",
                    label: "Messages",
                    selected: selectedTab === 4,
                    content: <QuoteMessages quoteId={jobQuoteID} />,
                    onClick: () => setSelectedTab(4),
                  },
                  {
                    id: "rfi",
                    label: "RFI",
                    selected: selectedTab === 5,
                    content: <QuoteRfi quoteId={jobQuoteID} />,
                    onClick: () => setSelectedTab(5),
                  },
                  {
                    id: "orders",
                    label: "Orders",
                    selected: selectedTab === 6,
                    content: (
                      <QuoteOrders
                        quoteId={jobQuoteID}
                        selectedQuote={selectedQuote}
                        httpsUrl={httpsUrl}
                      />
                    ),
                    onClick: () => setSelectedTab(6),
                  },
                  {
                    id: "metadata",
                    label: "Metadata",
                    selected: selectedTab === 7,
                    content: <QuoteMetadata quoteData={selectedQuote} />,
                    onClick: () => setSelectedTab(7),
                  },
                ]}
              />
            </Styled.QuoteDetailsStyled>
          ) : (
            <Styled.QuoteDetailsStyled className="inbox-detailRightside">
              {windowsUrl === Paths.quoteDetail ? (
                <p>Select a quote to see its details</p>
              ) : (
                <ShapedSkeleton
                  shape="0 0 200 20, 0 90 200 20, 0 40 400 30, 0 142 80 40, 890 5 72 36, 1030 5 36 36, 978 5 36 36, 1080 5 36 36, 184 142 80 40, 92 142 80 40, 276 142 80 40, 368 142 80 40"
                  width={1150}
                  height={182}
                  radius={4}
                  fullWidth={true}
                />
              )}
            </Styled.QuoteDetailsStyled>
          )}
        </section>
        {isCustomInfo ? (
          <LinkQuoteRecipientForm
            open={isCustomInfo}
            jobLotPackageId={jobLotPackageID}
            handlerID={sellerID}
            jobLotQuoteID={jobQuoteID}
            handleCloseModel={handleCloseModel}
          />
        ) : null}
        {isEstimator ? (
          <EstimatorForm
            open={isEstimator}
            assignedDate={assignDateToEstimator}
            salespersonName={estimator}
            salespersonData={salesPerson}
            jobQuoteID={jobQuoteID}
            handlerID={sellerID}
            salespersonID={creatorID}
            removeEstimatorFn={removeEstimatorFn}
            isLoadingForRemove={isLoading}
            handleCloseModel={handleCloseModel}
            isSuccess={isSuccess}
            handleChangeSuccess={handleChangeSuccess}
            hasSubQuotes={hasSubQuotes}
          />
        ) : null}

        {isLikelihoodOfClose ? (
          <LikelihoodOfCloseForm
            open={isLikelihoodOfClose}
            jobQuoteID={jobQuoteID}
            likelihoodOfClose={selectedQuote.likelihoodOfClose}
            likelyHoodComment={likelyHoodComment}
            handleCloseModel={handleCloseModel}
            handleChangeSuccess={handleChangeSuccess}
          />
        ) : null}

        {isExpirationDate ? (
          <ExpirationDateForm
            open={isExpirationDate}
            jobQuoteID={jobQuoteID}
            creatorId={creatorID}
            currentExpDate={expDate}
            handleCloseModel={handleCloseModel}
            handleChangeSuccess={handleChangeSuccess}
          />
        ) : null}
        {isDueDate ? (
          <DueDateForm
            open={isDueDate}
            jobQuoteID={jobQuoteID}
            creatorId={creatorID}
            currentDueDate={dueDate}
            handleCloseModel={handleCloseModel}
            handleChangeSuccess={handleChangeSuccess}
            modalType={dateType.dueDate}
            fieldTitle={newDueDate}
            header={fileDueDateModalLabel}
            revisedDueDate={revisedDueDate}
            showEditOption={dueDateVerifyFlag && Boolean(sellerID)}
          />
        ) : null}
        {isCloseDate ? (
          <DueDateForm
            open={isCloseDate}
            jobQuoteID={jobQuoteID}
            creatorId={creatorID}
            currentDueDate={closeDate}
            handleCloseModel={handleCloseModel}
            handleChangeSuccess={handleChangeSuccess}
            modalType={dateType.closeDate}
            fieldTitle={newCloseDate}
            header={changeCloseDate}
            showEditOption
          />
        ) : null}
        {isSalesperson ? (
          <SalespersonForm
            open={isSalesperson}
            assignedDate={selectedQuote.assignDateToEstimator}
            salespersonName={selectedQuote.salespersonName}
            salespersonData={salesPerson}
            jobQuoteID={jobQuoteID}
            salespersonID={salespersonID}
            handleCloseModel={handleCloseModel}
            handleChangeSuccess={handleChangeSuccess}
          />
        ) : null}
        {openDialog ? (
          <NotesDialog
            setOpenMiniNote={setOpenMiniNote}
            open={openDialog}
            notes={noteValue}
            category={noteCategory}
            close={openCloseNotes}
            quoteId={jobQuoteID}
            handlerID={DefaultLoginId}
            noteLength={500}
            followUp={false}
            highlighted={noteCategory === jobHighlights}
          />
        ) : null}
        {isChangeQuoteStatus ? (
          <>
            <ChangeQuoteStatusForm
              open={isChangeQuoteStatus}
              jobQuoteID={jobQuoteID}
              handleCloseModel={handleCloseModel}
              selectedQuote={selectedQuote}
              handleCommonSuccess={handleCommonSuccess}
              sellerID={sellerID}
              distributerHttpUrl={distributor?.httpsUrl}
            />
          </>
        ) : null}
      </ErrorBoundary>
    </>
  );
}
