import React, { memo } from "react";
import { useSelector } from "react-redux";
import { distributorData } from "../../../../sagas/distributor/selector";
import RowComp from "./RowComp"

interface Props {
    orderId: string;
    overviewData: any;
    loading: boolean;
}

const ProjectMangerRow = ({ orderId, overviewData = {}, loading }: Props) => {
    const { projectManager: projectManagerName, projectManagerPersonId } = overviewData
    const projectManager = { projectManagerId: projectManagerPersonId, projectManagerName: projectManagerName };

    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;
    const redirectUrl = `${httpsUrl}/manager/mOrder_updateProjectManager?orderID=${orderId}`

    return (
        <RowComp
            name={projectManager?.projectManagerName || "Not assigned"}
            isAssigned={projectManager?.projectManagerName}
            handleAssign={redirectUrl}
            assignText="Assign"
            handleChange={redirectUrl}
            changeText="Change"
            handleRemove={redirectUrl}
            removeText="Remove"
            isManager={true}
            initialsHover={projectManager.projectManagerId}
            loading={loading}
            className="project-manager-row"
        />
    );
}

export default memo(ProjectMangerRow);
