import React from "react";
import Button from "components/SPButton/Button";
import "./Title.scss";

const Title = ({
  count,
  title,
  btnLabel,
  btnIcon,
  btnLink,
  noFullGrid = false,
}: any) => {
  return (
    <div className="order-container">
      <p className="section-title d-flex flex-row align-items-center gap-2">
        {count !== undefined && (
          <span className="order-count">{count > 0 ? count : "No"}</span>
        )}
        <span className="order-title">{`${title}${count !== undefined && (count > 1 || count === 0) ? "s" : ""
          }`}</span>
      </p>
      {btnIcon && btnLink ? (
        <div
          className={`${noFullGrid ? "title-no-full-grid" : "title-full-grid"}`}
        >
          <Button
            label={btnLabel}
            leadingIcon={btnIcon}
            type="tertiary"
            customClassName="upload-btn"
            href={btnLink}
            target="_blank"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Title;
