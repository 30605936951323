import { memo } from "react";
import OverviewTable from "../overviewTable/overviewTable";
import ProjectManagerRow from "./ProjectManagerRow"
import ProjectRow from "./ProjectRow"
import GeneralRow from "./GeneralRow";
import Title from "../Title/Title";
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";

interface props {
    orderId: string;
    orderData: any;
    loading: boolean;
}

const Overview = ({ orderId, orderData = {}, loading }: props) => {

    const { customerPo, quote, makerPO, vendorRefNum, salesPersonName, SalesPersonID } = orderData.overview || {}
    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;
    const handleRedirect = `${httpsUrl}/manager/mOrder_UpdateJobName_PO.aspx?orderID=${orderId}`;
    const handleSalesPersonRedirct = `${httpsUrl}/manager/mOrder_updateSalesperson?orderID=${orderId}`;

    const data = [
        {
            label: "Customer PO",
            className: "customer-po project",
            value: <GeneralRow value={customerPo} loading={loading} handleRedirect={handleRedirect} />
        },
        {
            label: "Project",
            className: "project",
            value: <ProjectRow orderId={orderId} overviewData={orderData.overview} loading={loading} />
        },
        {
            label: "Project Manger",
            className: "project",
            value: <ProjectManagerRow orderId={orderId} overviewData={orderData.overview} loading={loading} />
        },
        {
            label: "Manufacturer PO",
            className: "project",
            value: <GeneralRow value={makerPO} loading={loading} handleRedirect={handleRedirect} />
        },
        {
            label: "Vendor Ref",
            className: "project",
            value: <GeneralRow value={vendorRefNum} loading={loading} handleRedirect={handleRedirect} />
        }
    ];

    //add quote only if it is avaiable
    if (quote) {
        data.splice(1, 0, {
            label: "Quote",
            className: "quote",
            value: quote || ""
        })
    }

    if (salesPersonName) {
        const salesPersonIndex = quote ? 2 : 1
        data.splice(salesPersonIndex, 0, {
            label: "Salesperson",
            className: "customer-po project",
            value: <GeneralRow value={salesPersonName} loading={loading} personId={SalesPersonID} handleRedirect={handleSalesPersonRedirct} />
        })
    }

    return (
        <>
            <div className="overview-table">
                <Title title={"Overview"} />
                <OverviewTable data={data} loading={loading} />
            </div>
        </>
    )
}

export default memo(Overview);
