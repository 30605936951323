import { endpoints } from "apps/pos/endpoints";
import serviceV1 from "services/config";
import serviceV2 from "services/config2";

const OD_API_USER = "users";
const OD_API_ORDER = "orders";

export const getProjectManagers = (payload: any) => {
  return serviceV1
    .get(
      `${OD_API_USER}/project/managers?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getProjectManagerName = (payload) => {
  return serviceV1
    .get(`${OD_API_USER}/${payload.personId}/types/${payload.nameType}/name`)
    .then((response) => {
      return response.data;
    });
};

export const getProjectManagerId = (orderId: string) => {
  return serviceV1
    .get(`${OD_API_ORDER}/${orderId}/project/managerId`)
    .then((response) => {
      return response.data;
    });
};

//new
export const getOrderDetails = (id: string) => {
  return serviceV2.get(endpoints.newOrderDetails(id)).then((response) => {
    return response.data;
  });
};

export const getOrderPlaceName = (id: string) => {
  return serviceV1.get(`${OD_API_ORDER}/${id}/placed/name`).then((response) => {
    return response.data;
  });
};

export const updateMarginValue = (payload) => {
  return serviceV1
    .put(endpoints.updateMargin(payload), payload)
    .then((response) => {
      return response.data;
    });
};

export const updateProjectValue = (payload) => {
  return serviceV1
    .put(endpoints.updateProjectName(payload), payload)
    .then((response) => {
      return response.data;
    });
};

export const getProfileDetails = (payload) => {
  return serviceV2
    .get(endpoints.profileDetails(payload))
    .then((response) => {
      return response.data;
    });
};

export const getOrderDocumentData = (payload) => {
  return serviceV2
    .get(endpoints.orderDocument(payload))
    .then((response) => {
      return response.data;
    });
};

export const getCustomerDetail = (payload) => {
  return serviceV2.get(endpoints.customerOverview(payload)).then((response) => {
    return response.data;
  });
};
export const getStatusLogs = (id: string) => {
  return serviceV2.get(`orderstatusblogs/${OD_API_ORDER}/${id}`).then((response) => {
    return response.data;
  });
};

export const getInternalNotes = (id: string) => {
  return serviceV2.get(`${OD_API_ORDER}/${id}/notes`).then((response) => {
    return response.data;
  });
};

export const getMessage = (id: string) => {
  return serviceV2.get(`messages/objects/${id}`).then((response) => {
    return response.data;
  });
};

export const getProjectId = (orderId: string) => {
  return serviceV1.get(`orders/${orderId}/customer/projectid`).then((response) => {
    return response.data;
  });
}

export const getProjectDetails = (projectId: string) => {
  return serviceV1.get(`projects/${projectId}`).then((response) => {
    return response.data;
  });
}

export const getOverviewQoute = (orderId: string) => {
  return serviceV1.get(`docs/orders/${orderId}/quote`).then((response) => {
    return response.data;
  });
}

export const detachOverviewProject = (payload) => {
  return serviceV1
    .delete(`projects/${payload.projectId}/users/${payload.handlerId}/orders/${payload.orderId}`)
    .then((response) => {
      return response.data;
    });
};

export const getPersonDetails = (personId: string) => {
  return serviceV1
    .get(`users/person/${personId}`)
    .then((response) => {
      return response.data;
    });
};


export const updateRequireDeliveryFlag = (payload) => {
  return serviceV2
    .put(`orders/${payload.orderId}/delivery/proof/required/${payload.requireProofOfDeliveryFlag}`, {})
    .then((response) => {
      return response.data;
    });
};

export const updateEmailRecieveOptout = (payload) => {
  return serviceV2
    .put(`users/${payload.personId}/customer/email/receive/${payload.noMailFlag}`, {})
    .then((response) => {
      return response.data;
    });
};

export const updateShowQRCode = (payload) => {
  return serviceV2
    .put(`/orders/${payload.orderId}/packingslip/qrcode/${payload.showQRCode}`, {})
    .then((response) => {
      return response.data;
    });
};

export const updateShowInvoicePrices = (payload) => {
  return serviceV2
    .put(`/orders/${payload.orderId}/invoice/prices/show/${payload.status}`, {})
    .then((response) => {
      return response.data;
    });
};

export const getItems = (payload) => {
  return serviceV2.get(`orders/${payload}/items`).then((response) => {
    return response.data;
  });
}

export const getProductStatus = (payload) => {
  return serviceV2
    .post(`stock/products/units/available`, payload)
    .then((response) => {
      return response.data;
    });
}

export const getProductInventory = (payload) => {
  return serviceV2
    .get(`stock/warehouses/0/products/${payload}/stockmainid/0/qtyallocated/0/awaitqty/0/locations?page=1&size=20`)
    .then((response) => {
      return response.data;
    });
};

export const getItemsTotal = (payload) => {
  return serviceV2.get(`orders/${payload}/fees/total`).then((response) => {
    return response.data;
  });
}

export const getShipmentIds = (payload) => {
  return serviceV2.get(`shipments/orders/${payload}`).then((response) => {
    return response.data;
  });
}

export const getShipment = (payload) => {
  return serviceV2.get(`shipments/${payload}`).then((response) => {
    return response.data;
  });
}

export const getSingleInternalNotes = (payload) => {
  return serviceV1.get(`orders/${payload}/note/internal`).then((response) => {
    return response.data;
  });
};

export const saveSingleInternalNotes = (payload) => {
  return serviceV1
    .put(`/orders/${payload.orderID}/note/internal`, payload)
    .then((response) => {
      return response.data;
    });
};

export const getInvoices = (payload: string) => {
  return serviceV2
    .get(`/orders/${payload}/invoices`)
    .then((response) => {
      return response.data;
    });
};
