import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Header } from "../components";
import Aside from "./Aside";

import { setPosFlowData } from "sagas/persistReducer/actions";
import { setLoginUser } from "sagas/user/actions";
import { getLoggedInUser } from "services/user/user.service";

import { RootState, user } from "../models";

import { sidenavState } from "constants/constant";
import { PosFlowDataProp } from "constants/interfaces";
import { setLocalStorageValue } from "utils/helper";
import { authorizationToken } from "utils/utils";

import { Paths } from "enums";
import "./layout.css";

function Layout({ children, themeSetter }: any) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [showBookmarks, setShowBookMark] = useState(false);
  const [showMenu, setShowMenuMark] = useState([]);
  const [currentUserData, setCurrentUserData] = useState<any>({});

  const dispatch = useDispatch();

  const decodedData = authorizationToken("Authorization");
  const { collapse, expand } = sidenavState
  const handleCollapsedChange = (checked) => {
    if (!checked) {
      setLocalStorageValue(collapse);
      setShowBookMark(false);
      setShowMenuMark([]);
    } else {
      setLocalStorageValue(expand)
    }
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const { loggedInUser } = useSelector<RootState, user>((state) => state.user);

  let pathname = window.location.pathname;
  const location = useLocation();
  const updatedPathname = location.pathname;
  useEffect(() => {
    pathname = window.location.pathname;
  }, [window.location.pathname]);

  useEffect(() => {
    if (Object.keys(loggedInUser).length) {
      setCurrentUserData(loggedInUser);
    } else {
      getLoggedInUser(decodedData?.DefaultLoginId).then(async (response) => {
        const isTakerSalesman = !!response?.roles?.find(
          (role) => role?.id === 7
        );
        const data = {
          ...posFlowData,
          salesman: {
            ...posFlowData?.salesman,
            ssoId: isTakerSalesman && response?.ssoId && response?.ssoId,
            salespersonName:
              isTakerSalesman && response?.ssoId && response?.fullName,
            fullName: isTakerSalesman && response?.ssoId && response?.fullName,
            firstName:
              isTakerSalesman && response?.ssoId && response?.firstName,
            lastName: isTakerSalesman && response?.ssoId && response?.lastName,
            email: isTakerSalesman && response?.ssoId && response?.email,
          },
        };
        await dispatch(setLoginUser(response));
        if (isTakerSalesman && response?.ssoId) {
          dispatch(setPosFlowData(data));
        }
        setCurrentUserData(response);
      });
    }
    checkLocalStorageValue();
  }, []);

  const checkLocalStorageValue = () => {
    const getNavState = localStorage.getItem("sidenavState");

    if (!getNavState) {
      setLocalStorageValue(collapse);
    } else {
      if (getNavState === expand) {
        setCollapsed(true);
      }
    }
  }

  const getClassName = (pathname, updatedPathname, collapsed, posFlowData) => {
    if (pathname.match("/springboard")) {
      return "main-content-collapsed springboard-main-content";
    } else if (pathname.match(Paths.customer) || pathname.match(Paths.posChargeCreditCard)) {
      return "overflow-hidden main-content-collapsed";
    } else if (
      updatedPathname.match(Paths.posSalesHistory) ||
      updatedPathname.match(Paths.posItemLedger) ||
      updatedPathname.match(Paths.posQuotesHistory) ||
      updatedPathname.match(Paths.purchaseHistory) ||
      updatedPathname.match(Paths.posItemHistory)
    ) {
      return "main-content-collapsed overflow-auto";
    } else if (updatedPathname.match(Paths.posCart)) {
      return "main-content-collapsed overflow-auto cart-main-layout";
    } else if (pathname.match(Paths.product)) {
      return "main-content-collapsed product-detail-main-content";
    } else if (
      (pathname.match(Paths.orderDetail) ||
        updatedPathname.match(Paths.orderDetail))
    ) {
      return "new-order-detail";
    } else if (!collapsed && pathname.match(Paths.adminUsersPath)) {
      return "main-content-collapsed";
    } else if ((posFlowData?.addProductPage) || updatedPathname.match(Paths.customer) || updatedPathname.match(Paths.posChargeCreditCard)) {
      return "main-content-collapsed";
    } else if (
      pathname.match(Paths.adminSetting) ||
      pathname.match(Paths.posReviewItems)
    ) {
      return "overflow-hidden main-content-collapsed pl-0 pr-0";
    } else if (!collapsed || pathname.match(Paths.adminUsersPath)) {
      return "main-content-collapsed";
    } else {
      return "main-content";
    }
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""} jumper-app`}>
      <Header
        setter={(item) => themeSetter(item)}
        handleToggleSidebar={handleCollapsedChange}
        collapsed={collapsed}
        currentUserData={currentUserData}
        setCurrentUserData={setCurrentUserData}
      />
      <Aside
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        showBookmarks={showBookmarks}
        setShowBookMark={setShowBookMark}
        showMenu={showMenu}
        setShowMenuMark={setShowMenuMark}
        currentUserData={currentUserData}
      />
      <div
        className={getClassName(
          pathname,
          updatedPathname,
          collapsed,
          posFlowData
        )}
        id={
          pathname
            .toLowerCase()
            .match("/app/pos/neworder" || "/app/pos/newOrder")
            ? "newOrderMainContentId"
            : ""
        }
      >
        {children}
      </div>
    </div>
  );
}

export default Layout;