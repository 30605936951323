import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";
import { ReactElement } from "react";

interface TooltipProps {
  title: string;
  placement?: MuiTooltipProps["placement"];
  children: ReactElement;
}

const Tooltip = ({ title, placement = "top", children }: TooltipProps) => {
  return (
    <MuiTooltip
      arrow
      title={title}
      placement={placement}
      slots={{
        arrow: () => (
          <svg
            viewBox="0 0 30 10"
            style={{
              position: "absolute",
              bottom: -10,
              left: "50%",
              transform: "translateX(-50%)",
              height: 10,
              width: 30,
            }}
          >
            <path
              d="M0.0699463 0H28.93C23.4576 0 18.5592 3.39457 16.6377 8.51852C15.897 10.4938 13.1029 10.4938 12.3622 8.51852C10.4407 3.39457 5.54233 0 0.0699463 0Z"
              fill="#2C2C2E"
            />
          </svg>
        ),
      }}
      slotProps={{
        transition: {
          timeout: 0,
        },
        tooltip: {
          sx: {
            backgroundColor: "#2C2C2E",
            color: "white",
            fontSize: "14px",
            borderRadius: 500,
            lineHeight: "22px",
            padding: "6px 12px",
            fontWeight: "400",
            boxShadow: "0px 6px 10px 0 rgba(0,0,0,0.1)",
          },
        },
        arrow: {
          sx: {
            "&::before": {
              display: "none",
            },
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
