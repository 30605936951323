import { DocumentWithTable } from "./DocumetsWithTable";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import { memo } from "react";
import Title from "../Title/Title";

export const DocumentsWithFilters = memo(({
  count,
  uniqueDocuments,
  loading,
  handleUpload,
  error
}: any) => {
  return (
    <>
      <Title count={count} title="Document" btnLabel="Upload" btnIcon="upload" btnLink={handleUpload} />
      <div>
        {loading || error ? (
          <ShapedSkeleton
            width={600}
            height={150}
            shape="
                    0 50 60 80,      
                    70 50 50 20,    
                    280 50 50 20,   
                    500 50 80 20,    
                    70 80 50 20,    
                    70 110 50 20,   
                    130 110 50 20, 
                    190 110 60 20    
                  "
            radius={4}
            fullWidth={true}
          />
        ) : (
          <DocumentWithTable data={uniqueDocuments} />
        )}
      </div>
    </>
  );
})
