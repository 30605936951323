import { memo } from "react";
import OrderTypeIcon from "../Icon/OrderTypeIcon";
import Icon from "apps/orderdetail/components/Icon/Icon";
import { OrderClosedData } from "../orderData/OrderData";
import { Grid } from "@material-ui/core";
import Tooltip from "../Tooltip";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton"

import {
  displayDateFormatValue,
  setDateWithFormat,
  getRelativeTime,
  formatDate,
} from "utils/utils";
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";
// import OrderDetailsLoader from "./OrderDetailsLoader";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { ReplacementOrder } from "./ReplacementPopup";
import OrderStatus from "./OrderStatus";
import { ip, labels } from "apps/orderdetail/helper/commonConstants";

function removeSpacesAndLowerCase(str: string): string {
  return str ? str.replace(/\s+/g, "").toLowerCase() : "";
}

const Badge = ({ color, className, text }) => {
  return (
    <span className="order-status" style={{ backgroundColor: color }}>
      <span className={`subtitle2 text-secondary ${className}`}>{text}</span>
    </span>
  );
};

const OrderPlacedPopup = ({ orderPlacedData }) => {
  const { ipAddress, ipStatus, host, closeDate, placeby, placeDate } =
    orderPlacedData;
  const closeTime = closeDate ? new Date(closeDate) : "";

  return (
    <>
      {closeDate ?
        <div className="flex-gap-1-5">
          <Icon name="check_circle" size="24" className="text-green-primary" />
          <p className="order-close text-primary">Order closed</p>
        </div>
        : <></>}
      <section className={`order-detail-report-section ${closeDate ? "pt-2" : ""}`}>
        {closeDate ?
          <>
            <OrderClosedData label="Close Date" value={formatDate(closeDate)} />
            <OrderClosedData
              label="Close Time"
              value={setDateWithFormat(
                closeTime,
                displayDateFormatValue.hourMinAndSec
              )}
              isBottomBorder
            />
          </> : <></>
        }
        <OrderClosedData label="Placed by" value={placeby} />
        <Grid container className="order-detail-report-container">
          <Grid container className="report-div">
            <Grid item xs={12} sm={5}>
              <div className="title text-secondary">
                <span>Date</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={7}>
              <div>
                <span className="title text-primary ml-2">
                  {formatDate(placeDate)}
                </span>
                <br />
                <span className="fs-14 inter-reg span-info-sec ml-2">
                  {getRelativeTime(placeDate)}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <OrderClosedData
          label="Time"
          value={setDateWithFormat(
            placeDate,
            displayDateFormatValue.hourMinAndSec
          )}
          isBottomBorder
        />
        <OrderClosedData
          label="IP Address"
          value={
            <div>
              <span style={{ marginRight: "5px", wordBreak: "break-all" }}>
                {ipAddress}
              </span>
              {ipStatus === ip.notInUSA && (
                <Badge
                  text={"IP not in USA"}
                  color={"#ffebea"}
                  className={"cancelled-status"}
                />
              )}
            </div>
          }
          isEllipsis
        />
        <OrderClosedData label="Host" value={host} isEllipsis />
      </section>
    </>
  );
};

const HandleLoading = (({ loading, children, height, width }) => {
  return (
    <>
      {loading ?
        <ShapedSkeleton
          width={width}
          height={height}
          shape={`0 0 ${width} ${height}`}
          radius={4}
          fullWidth={false}
        /> : children}
    </>
  )
})

const OrderDetails = ({ orderId, orderData = {}, loading }: any) => {
  const distributor = useSelector(distributorData);
  const { httpsUrl } = distributor;

  const {
    jobName,
    orderStatusName,
    orderType,
    orderTypeName,
    orderTypeNameLabel,
    placeby,
    placeDate,
    custNote,
  } = orderData;
  const custCompanyName = orderData?.buyer?.custCompanyName;

  const isCreditCardWithType = [
    "visa",
    "mastercard",
    "discover",
    "americanexpress",
  ].includes(removeSpacesAndLowerCase(orderTypeName));

  const viewInvoice = `${httpsUrl}/manager/mOrder_invoice?orderID=${orderId}`;
  const addCost = `${httpsUrl}/manager/mOrder_GMShipFeeList?orderID=${orderId}`;
  const createRMA = `${httpsUrl}/manager/mCreditMemo_return_CreateCustRMA?orderID=${orderId}`;
  const redirectToNotes = `${httpsUrl}/manager/mOrder_UpdateCustNote.aspx?orderID=${orderId}`;
  const editJobName = `${httpsUrl}/manager/mOrder_change_option?orderID==${orderId}`;
  const notForReleaseOrder = orderData.notReleaseFlag;
  const replcementOrderId = orderData.replacedOrderID;

  // if (loading) {
  //   return <OrderDetailsLoader />;
  // }

  return (
    <>
      <div className="flex-column-gap-2" id="overview">
        <div className="order-flags">
          {replcementOrderId ? (
            <>
              <a
                className="order-flag replacement-title-btn"
                href={`order/${replcementOrderId}`}
              >
                <MaterialIcon
                  icon={"restart_alt"}
                  size="24"
                  classes="replace-restart-alt"
                />
                <p className="subtitle2">Replacement of #{replcementOrderId}</p>
              </a>
            </>
          ) : (
            <></>
          )}
          {notForReleaseOrder ? (
            <div className="order-flag not-for-release">
              <MaterialIcon icon={"lock"} size="24" classes="release-lock" />
              <p className="subtitle2">{labels.notForReleaseLabel}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <h1>
          <p className="order-job-title">
            <span
              className={`${jobName || loading ? "text-tertiary" : "text-primary"
                } whitespace-nowrap`}
            >
              {`#${orderId}`}
            </span>
            &nbsp;
            <span className="sr-only">, </span>
            <HandleLoading loading={loading} height={32} width={"236"} >
              {jobName ? (
                <Tooltip title="Edit Job name">
                  <a
                    href={editJobName}
                    target="_blank"
                    className="text-primary edit-job-name"
                  >
                    <span className="job-name">{jobName}</span>
                    &nbsp;
                    <MaterialIcon
                      icon="edit"
                      size="24"
                      classes="edit-job-name-icon"
                    />
                  </a>
                </Tooltip>
              ) : (
                <a
                  href={editJobName}
                  className="btn od-btn-secondary od-btn-small interactive add-job-name"
                  target="_blank"
                >
                  <MaterialIcon icon="add" size="18" />
                  <span>{labels.addJobName}</span>
                </a>
              )}
            </HandleLoading>
          </p>
        </h1>

        <div className="flex-gap-2-items-center">
          <div className="subtitle2 text-secondary flex-gap-4 align-items-center">
            <HandleLoading loading={loading} height={26} width={"91"} >
              <OrderStatus orderStatusName={orderStatusName} />
            </HandleLoading>
            <HandleLoading loading={loading} height={22} width={"44"} >
              <OrderTypeIcon
                orderType={
                  orderType === 1 && isCreditCardWithType
                    ? removeSpacesAndLowerCase(orderTypeName)
                    : orderType
                }
                isCreditCardWithType={isCreditCardWithType}
                orderTypeNameLabel={orderTypeNameLabel}
              />
            </HandleLoading>
            <HandleLoading loading={loading} height={16} width={"292"} >
              <button
                className="order-detail-placed-title interactive text-secondary"
                id="dropdownMenuODButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <p className="subtitle2 ">
                  {`Placed ${placeby ? "by " + placeby : ""} ${placeDate ? "on " + formatDate(placeDate) : ""
                    } `}
                </p>
                <Icon name="expand_more" size="20" />
              </button>
            </HandleLoading>
            <div
              className="dropdown-menu p-4 order-detail-container-dropdown order-detail-container-dropdown order-detail-container-dropdown-order-closed"
              aria-labelledby="dropdownMenuODButton"
              onClick={(e) => e.stopPropagation()}
            >
              <OrderPlacedPopup orderPlacedData={orderData} />
            </div>
          </div>
        </div>

        <div
          className={`flex-gap-2-items-center customer-name-group ${custNote ? "" : "no-delivery-note"
            }`}
        >
          <HandleLoading loading={loading} height={16} width={"102"} >
            <p className="p2">{custCompanyName}</p>
          </HandleLoading>
          {custNote ? (
            <Tooltip title="Edit Delivery note">
              <a
                href={redirectToNotes}
                target="_blank"
                className="text-primary edit-delivery-note"
              >
                <p className="customer-delivery-note">
                  <svg
                    className="chat-bubble-tail"
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0C6.57297 5.18919 6.05405 16 6.05405 16L12.973 5.18919C8.82162 1.72973 2.59459 0.288288 0 0Z"
                      fill="#F2F2F7"
                    />
                  </svg>

                  <span className="p2 text-primary">
                    {custNote.split("").map((char, index) => (
                      <span
                        key={char + index}
                        className="delivery-note-char"
                        style={{
                          animationDelay: `${0.005 * index}s`,
                        }}
                      >
                        {char}
                      </span>
                    ))}
                  </span>
                </p>
              </a>
            </Tooltip>
          ) : (
            <HandleLoading loading={loading} height={22} width={"102"} >
              <a
                className="text-primary add-delivery-note btn od-btn-secondary od-btn-small"
                href={redirectToNotes}
                target="_blank"
              >
                <MaterialIcon icon="add" size="16" />
                Add Delivery note
              </a>
            </HandleLoading>
          )}
        </div>
        <HandleLoading loading={loading} height={23} width={"180"} ><></></HandleLoading>
      </div>

      <div className="flex-gap-2">
        <a href={viewInvoice} className="btn interactive" target="_blank">
          <span>{labels.viewInvoiceLabel}</span>
        </a>
        <a
          href={addCost}
          className="btn od-btn-secondary interactive"
          target="_blank"
        >
          <span>{labels.addCostLabel}</span>
        </a>
        <a
          href={createRMA}
          className="btn od-btn-secondary interactive"
          target="_blank"
        >
          <span>{labels.createRMACLabel}</span>
        </a>
      </div>

      {orderData.replacementOrders?.length > 0 ? (
        <ReplacementOrder replacedOrdersList={orderData.replacementOrders} />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(OrderDetails);
