import "@fontsource/inter";
import { Form, FormikProvider } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container, PosButton, PosImage, PosPageHeading } from "apps/pos/Common/uiCore";
import POSTextareaAutosize from "components/Input/POSTextareaAutosize";
import { jobAndReceiptNameRegex, removeSpaceRegex } from "constants/constant";
import { CheckIcon, ClearIcon } from "constants/icons";
import images from "constants/images";
import { Paths, Placeholders } from "enums";
import { Distributor, PosResponseProps, RootState } from "models";
import Button from "./Common/Button/Button";
import HandleLongData from "./Common/HandleLongData";
import { ErrorMessage } from "./formik/errorMessage";
import ShowGenericCustomerOptions from "./PaymentMethod/Components/ShowGenericCustomerOptions";
import useSaveForLaterFormik from "./useSaveForLater";

import {
  clearLoadCartDetails,
  recipientInfoSwitchInfoData,
  setCustomerAlertHide,
  setExemptTax,
  setPosFlowData,
  setTaxExemptManually,
  skipOrdererInfo
} from "sagas/persistReducer/actions";
import {
  clearSearchCustomer,
  savedDraftsReset,
  setJobNameForAll,
  setMarkAsQuoteReducer,
  setShippingInstructionNote,
  setStoreCreditAmout,
  suggestedProductsClear,
} from "sagas/pos/actions";

import { PosFlowDataProp } from "constants/interfaces";
import { beforeUnloadCart } from "utils/helper";

import "./neworder.scss";

const SaveForLater = ({
  saveForLaterAPI,
  setCartEmpty,
  setSuggestionListVisible,
  setSuggestedProducts,
  setSaveForLaterClickedFrom,
  setShowSaveForLater,
  saveForLaterClickedFrom
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { posFlowData, loadCartDetails, recipientSwitchInfo } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { cartProducts } = posFlowData || {};

  // const isShowPickedUpMessage = cartProducts?.length > 0 &&
  //   cartProducts[0].pickedQtyWithLocation?.locations?.some(location => location.pickedQty !== undefined);

  const { cartDraftSuccess, isLoading } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const { jobNameForAll } = useSelector<RootState, PosResponseProps>((state) => state.pos)

  const { customer, savedCart, isLogoutClick, shiptoFacilities, priceVisible } = posFlowData || {};
  const { isPosGeneric } = customer || {};
  const { customer: loadCartCustomer } = loadCartDetails || {};
  const { isPosGeneric: loadCartIsPosGeneric } = loadCartCustomer || {};
  const { jobName } = posFlowData?.savedCart || {};
  const isCustomerInfo = window.location.pathname.includes("customer");

  const [editFlag, setEditFlag] = useState(false);
  const [showSaveLater, setShowSaveLater] = useState(false);
  const [savedJobName, setSavedJobName] = useState(
    jobName
  );
  const { selectQuoteName } = posFlowData || {};

  useEffect(() => {
    if (!jobName) {
      setSavedJobName(selectQuoteName)
      setEditFlag(true)
    }

  }, [selectQuoteName, jobName])


  const handleCustomerDetail = (values) => {
    let data;
    const { fullName, phoneNumber, extCode, email: genericEmail, companyName: genericCompanyName } = values || {};

    const splitFullname = (fullName?.split(" "))
    const genericUserFirstName = splitFullname?.slice(0, -1).join(" ");
    const genericUserLastName = splitFullname[splitFullname?.length - 1];
    const genericPhoneInfo = {
      type: Placeholders.phoneType,
      number: phoneNumber,
      extn: extCode,
      intCode: "1",
    };
    if (recipientInfoSwitch) {
      const phoneRemoveSpace = phoneNumber ? phoneNumber.replace(removeSpaceRegex, '') : ""
      const areaCodeInfo = phoneRemoveSpace && phoneRemoveSpace?.substring(0, 3);
      const prefixInfo = phoneRemoveSpace && phoneRemoveSpace?.substring(3, 6);
      const lineNumInfo = phoneRemoveSpace && phoneRemoveSpace?.substring(6, 10);
      data = {
        ...posFlowData,
        shiptoFacilities: {
          ...shiptoFacilities,
          recipient: {
            ...shiptoFacilities?.recipient,
            firstName: genericUserFirstName,
            lastName: genericUserLastName,
            companyName: genericCompanyName || null,
            email: genericEmail || null,
            phone1: areaCodeInfo,
            phone2: prefixInfo,
            phone3: lineNumInfo,
            phone4: extCode || ""
          }
        }
      }
      dispatch(recipientInfoSwitchInfoData(true))
    } else {
      data = {
        ...posFlowData,
        firstName: genericUserFirstName,
        lastName: genericUserLastName,
        phone: phoneNumber ? genericPhoneInfo : null,
        email: genericEmail || null,
        companyName: genericCompanyName || null,
      };
    }
    dispatch(setPosFlowData(data));
  };

  const [recipientInfoSwitch, setRecipientInfoSwitch] = useState(
    recipientSwitchInfo
  );

  useEffect(() => {
    setRecipientInfoSwitch(recipientSwitchInfo)
  }, [])

  const handleRecipientInfoCheck = () => {
    setRecipientInfoSwitch((previousValue) => !previousValue);
    dispatch(recipientInfoSwitchInfoData(!recipientInfoSwitch))
  };

  useEffect(() => {
    if (cartDraftSuccess && isCustomerInfo) {
      const data = {
        ...posFlowData,
        isCartAvailable: false,
        isLogoutClick: false,
        addDocument: [],
        poNumber: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        companyName: "",
      };
      dispatch(setPosFlowData(data));
      dispatch(setShippingInstructionNote(""));
      dispatch(setJobNameForAll(""));
      dispatch(setMarkAsQuoteReducer(false));
      dispatch(setCustomerAlertHide(true));
      dispatch(setStoreCreditAmout(""));
      dispatch(recipientInfoSwitchInfoData(false));
      dispatch(skipOrdererInfo(false))
      navigate(Paths.posNewOrder)
    }
  }, [cartDraftSuccess])

  useEffect(() => {
    if (jobNameForAll) {
      setSavedJobName(jobNameForAll)
    }
  }, [jobNameForAll, jobName]);

  const formik = useSaveForLaterFormik(setShowSaveForLater, saveForLaterAPI, savedJobName, setSaveForLaterClickedFrom);
  const { setFieldValue, setFieldError } = formik || {};

  const loadSaveForLaterAPI = () => {
    if (!isLoading) {
      setSaveForLaterClickedFrom(null)
      setShowSaveForLater(isCustomerInfo);
      setTimeout(() => {
        saveForLaterAPI(savedJobName);
      }, 1000);
    }
  };

  const redirectToNewOrder = () => {
    dispatch(clearLoadCartDetails());
    setSuggestedProducts([]);
    setCartEmpty(true);
    setSuggestionListVisible(true);
    dispatch(clearSearchCustomer());
    dispatch(setExemptTax(false));
    dispatch(setTaxExemptManually(false))
    dispatch(savedDraftsReset());
    dispatch(suggestedProductsClear());
    const data = {
      ...posFlowData,
      cartProducts: [],
      addInStorage: [],
      customer: {},
      savedCart: {},
      schedule: {},
      taker: {},
      pickOrderQuantity: [],
      pickQuantityArr: [],
      finalQtyArrayInfoData: [],
      shippingCharge: { shippingAmt: 0, shippingAmtTax: 0 },
      priceVisible: priceVisible,
      isCartAvailable: false,
      isLogoutClick: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      companyName: "",
      viaSwitchCustomer: false,
      scheduleAdded: false
    };
    dispatch(setPosFlowData(data));
    dispatch(setShippingInstructionNote(""));
    dispatch(setJobNameForAll(""));
    dispatch(setMarkAsQuoteReducer(false));
    dispatch(setCustomerAlertHide(true));
    dispatch(setStoreCreditAmout(""));
    beforeUnloadCart([]);
    setSaveForLaterClickedFrom(null)
    setShowSaveForLater(false);
    if (isLogoutClick) {
      window.location.href = `${distributor.httpsUrl}/sso/logout`;
    } else {
      navigate(Paths.posNewOrder);
    }
  };
  const { caution, changeIcon } = images;
  const handleJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setFieldValue("title", e.target.value.trim())
      saveJobName(e.target.value)
    }
  }

  const handleSavedJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setFieldValue("title", e.target.value.trim())
      saveJobName(e.target.value)
    }
  }

  const cancelName = () => {
    saveJobName(jobName)
    setEditFlag(false);
  }

  const saveClick = () => {
    if (!savedJobName?.trim()) {
      setFieldError('title', '');
      return
    }
    saveJobName(savedJobName)
    setEditFlag(false);
  }

  const saveJobName = (value) => {
    setSavedJobName(value)
    dispatch(setJobNameForAll(value))
  }

  const setFlag = () => {
    setEditFlag(true);
  }
  return (
    <>
      <Container className="modal-content-style modal-container-styling">
        {(isPosGeneric && !showSaveLater) || (loadCartIsPosGeneric && !showSaveLater) ?
          <ShowGenericCustomerOptions
            handleRecipientInfoCheck={handleRecipientInfoCheck}
            handleCustomerDetail={handleCustomerDetail}
            setShowSaveLater={setShowSaveLater}
            isSaveForLater
          />
          : (
            <FormikProvider value={formik}>
              <Form>
                <main>

                  {(savedCart?.products?.length || 0) === 0 ? (
                    <div>
                      <div className="pricing-modal-header">
                        <PosPageHeading heading="Save for later" blackHeading />
                      </div>
                      {!saveForLaterClickedFrom && <div className="mt-4 mb-4">
                        <h3 className="int-20-reg-bold">
                          Save the cart in its current state and pick up where you left
                          off, anytime later.
                        </h3>
                      </div>}
                      {saveForLaterClickedFrom && <div className="save-document-count">
                        <span>Closing the cart will remove {cartProducts?.length > 1 ? 'all' : ''} {cartProducts?.length} item{cartProducts?.length > 1 ? 's' : ''}. Save it to resume anytime later.</span>
                      </div>}
                      {/* {isShowPickedUpMessage ? ( */}
                      <div className="save-later-message">
                        <PosImage src={caution} alt="caution" />
                        <span className="save-later-message-style int-14-reg">
                          To maintain accurate stock records, picking information is not saved.
                        </span>
                      </div>
                      {/* ) : null} */}
                      <div>
                        <div className="order-num-input ml-5">
                          <span className="form-field-title text-black">
                            Set a job name for reference
                          </span>
                        </div>
                        <div className="margintop-10 text-area-focus">
                          <POSTextareaAutosize
                            id="title"
                            name="title"
                            className="double-bordered-input"
                            value={savedJobName}
                            onChange={handleJobName}
                          />
                        </div>
                        <ErrorMessage name="title" />
                      </div>
                      <div className="shipping-btns mt-5 flex-row-reverse">
                        <PosButton
                          startIcon={<ClearIcon />}
                          variant="contained"
                          color="primary"
                          className="modal-select-default-btn ship-btn justify-content-start save-dont-save-btn btn"
                          onClick={redirectToNewOrder}
                        >
                          Don't Save
                        </PosButton>
                        <PosButton
                          variant="contained"
                          startIcon={<CheckIcon />}
                          className="modal-select-default-btn ship-btn-dark justify-content-start save-dont-save-btn btn"
                          onClick={loadSaveForLaterAPI}
                          disabled={isLoading}
                        >
                          Save
                        </PosButton>
                      </div>
                    </div >
                  ) : !editFlag ? (
                    <div>
                      <div className="pricing-modal-header">
                        <h1 className="select-heading">Save for later</h1>
                      </div>
                      {!saveForLaterClickedFrom && <div className="select-ws-text ">
                        <h3 className="select-schedule-list">
                          Save the cart in its current state and pick up where you left
                          off, anytime later.
                        </h3>
                      </div>}
                      {saveForLaterClickedFrom && <div className="save-document-count pb-4">
                        <span>Closing the cart will remove {cartProducts?.length > 1 ? 'all' : ''} {cartProducts?.length} item{cartProducts?.length > 1 ? 's' : ''}. Save it to resume anytime later.</span>
                      </div>}
                      <div>
                        <div className="facility-items-align">
                          <div className="facility-box pr-5">
                            <span className="shipping-subheading">Job name</span>
                            <HandleLongData jobName={savedJobName} className="int-18-reg-bold word-break-all" />
                          </div>
                          <Button
                            className="cart-item-btn-change icn-btn edit-btn border-0 button-focus-class"
                            onClick={setFlag}
                          >
                            <PosImage src={changeIcon} alt="edit" />
                            <span className="cart-item-change icn-btn">Edit</span>
                          </Button>
                        </div>
                        <div className="slim-liner mtb-10" />
                        {/* LAST SAVED : code required
                      <div className="facility-box">
                        <span className="shipping-subheading">Last saved</span>
                        <span className="int-18-reg-bold">
                          2 minutes ago, 12:41 AM
                          <img src={updateLogo} alt="caution" />
                        </span>
                      </div>
                      <div className="slim-liner mtb-10" /> */}
                      </div>

                      {/* {isShowPickedUpMessage ? ( */}
                      <div className="save-later-message mt-4">
                        <PosImage src={caution} alt="caution" />
                        <span className="save-later-message-style int-14-reg">
                          To maintain accurate stock records, picking information is not saved.
                        </span>
                      </div>
                      {/* ) : null} */}
                      {posFlowData?.cartProducts?.length > 0 && (
                        <div className="customer-button-section flex-row-reverse justify-start">
                          <div className="btn-change-customer">
                            <PosButton
                              variant="contained"
                              className="btn-discard btn save-for-later-modal-btn btn"
                              startIcon={<ClearIcon />}
                              onClick={redirectToNewOrder}
                            >
                              Don't Save
                            </PosButton>
                          </div>
                          <div className="btn-change-customer">
                            <PosButton
                              variant="contained"
                              className="btn-saveforlater mt-0 btn save-for-later-modal-btn btn"
                              startIcon={<CheckIcon />}
                              onClick={loadSaveForLaterAPI}
                            >
                              Save For Later
                            </PosButton>
                          </div>

                        </div>
                      )
                      }
                    </div >
                  ) : (
                    <div>
                      <div className="pricing-modal-header">
                        <PosPageHeading heading="Edit Job name" blackHeading />
                      </div>
                      <div>
                        <div className="order-num-input ml-5">
                          <span className="int-16-reg">
                            Set a job name for reference
                          </span>
                        </div>
                        <div className="margintop-10 text-area-focus">
                          <POSTextareaAutosize
                            id="title"
                            name="title"
                            className="double-bordered-input"
                            value={savedJobName}
                            onChange={handleSavedJobName}
                          />
                        </div>
                        <ErrorMessage name="title" />
                      </div>
                      <div className="btns-container-discard-save mt-5 flex-row-reverse">
                        <Button
                          classes="btn-savechanges job-name-save-btn button-focus-class"
                          onClick={saveClick}
                        >
                          <CheckIcon />Save Job Name
                        </Button>
                        <Button
                          type="reset"
                          classes="btn-discard job-name-cancel-btn button-focus-class"
                          onClick={cancelName}
                        >
                          <ClearIcon />Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </main>
              </Form >
            </FormikProvider >
          )
        }

      </Container >
    </>
  );
};
export default SaveForLater;
