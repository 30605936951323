import { useQuery } from "@tanstack/react-query";
import { getStatusLogs } from "./service";

export const useGetStatusLogs = (payload) => {
  const { isError, isLoading, isSuccess, data } = useQuery(
    ["get-status-logs-details", payload],
    async () => {
      return getStatusLogs(payload);
    },
    {
      retry: false,
    }
  );

  return {
    isError,
    isLoading,
    isSuccess,
    data,
  };
};
