import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import React from "react";
import PersonComp from "../PersonComp/PersonComp";

interface Props {
    name: React.ReactNode;
    value?: React.ReactNode;
    isAssigned: boolean,
    handleAssign?: any,
    handleChange?: any,
    handleRemove?: any,
    assignText?: string,
    changeText?: string,
    removeText?: string,
    isManager?: boolean,
    loading: boolean,
    initialsHover?: React.ReactNode,
    className?: string
}

const RowComp = ({
    name,
    value,
    isAssigned,
    handleAssign,
    handleChange,
    handleRemove,
    assignText,
    changeText,
    removeText,
    isManager = false,
    loading,
    initialsHover,
    className = ""
}: Props) => {
    if (loading) {
        return (
            <>
                <ShapedSkeleton
                    width={"130"} // Adjust width to fit the content
                    height={37} // Height to fit the avatar and message
                    shape="0 0 130 30"
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />
            </>
        )
    }


    return (
        <div className={`project-details ${className}`}>
            <div className="project-details-name">
                <div className="project-details-name-owner">
                    {initialsHover && isAssigned ? (
                        <>
                            <PersonComp personName={name} personId={initialsHover} />
                        </>
                    ) : null}
                    {(!initialsHover || (!isAssigned && isManager)) && <div
                        id={isManager ? "manager" : "project-name"}
                        className={`project-details-name-owner ${!isAssigned && "not-assigned"} ${!name && "text-tertiary"}`}
                    >
                        {name}
                    </div>}
                    {value && !isManager ? (
                        <div
                            id="project-value"
                            className={`project-details-name-owner ${!isAssigned && "not-assigned"}`}
                        >
                            {value ? `$${value}` : null}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="project-details-button button-hide">
                {!isAssigned ? (
                    <a className="btn od-btn-secondary w-100" href={handleAssign} target="_blank">
                        {assignText}
                    </a>
                ) : (
                    <>
                        {
                            changeText && <a className="btn od-btn-secondary" href={handleChange} target="_blank">
                                {changeText}
                            </a>
                        }
                        {
                            removeText && <a className="btn od-btn-danger" href={typeof handleRemove == "string" ? handleRemove : null} target="_blank" onClick={typeof handleRemove == "function" ? handleRemove : null}>
                                {removeText}
                            </a>
                        }
                    </>
                )}
            </div>
        </div >
    );
};

export default RowComp;
