import React, { memo } from "react";
import { useDetachProject } from "../../modules/orderDetailsContainer/hook/useDeleteOverviewProject";
import { useSelector, useDispatch } from "react-redux";
import { distributorData } from "../../../../sagas/distributor/selector";
import RowComp from "./RowComp";
import { authorizationToken } from "utils/utils";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

interface Props {
    orderId: string;
    overviewData: any;
    loading: boolean;
}

const ProjectRow = ({ orderId, overviewData = {}, loading }: Props) => {
    const { projectId, project, ProjectValue } = overviewData
    const dispatch = useDispatch();

    const { DefaultLoginId } = authorizationToken("Authorization") || {};
    const projectIntialDetails = { projectId: projectId, projectName: project, value: ProjectValue };

    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;

    const { detachProject } = useDetachProject();

    const handleAssign = `${httpsUrl}/manager/mOrder_attachToCustProject?orderID=${orderId}`
    const handleChange = `${httpsUrl}/manager/mCust_project_detail?projectID=${projectIntialDetails.projectId}`

    const handleRemoveProject = async (e) => {
        e.preventDefault();
        const payload = {
            orderId,
            projectId: projectIntialDetails.projectId,
            handlerId: DefaultLoginId
        };
        try {
            await detachProject(payload);
        } catch (error) {
            dispatch(snakbarShow({ message: "Failed to detach." }));
        }
    }

    return (
        <RowComp
            name={projectIntialDetails.projectName || "Not attached"}
            value={projectIntialDetails.value}
            isAssigned={projectIntialDetails.projectName}
            handleAssign={handleAssign}
            handleChange={handleChange}
            handleRemove={handleRemoveProject}
            assignText="Attach to Customer project"
            changeText="Manage"
            removeText="Detach"
            loading={loading}
            className="project-row"
        />
    )
}

export default memo(ProjectRow);