import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { lazy, Suspense } from "react";

import CustomerDetails from "apps/orderdetail/components/CustomerDetails/customerDetails";
import Nav from "apps/orderdetail/components/nav";
import Overview from "apps/orderdetail/components/Overview/Overview";
import { navItems } from "apps/orderdetail/helper/commonConstants";

import { useGetStatusLogs } from "./hook/useStatusLogs";
import { useGetInternalNotes } from "./hook/useInternalNotes";
import { useGetMessage } from "./hook/useGetMessage";
import { useGetOrderDetails } from "./hook/useGetOrderDetails";

import { distributorData } from "sagas/distributor/selector";

import { OrderManageSection } from "apps/orderdetail/components/OrderManage/OrderManageSection";
import { OrderCommonLog } from "apps/orderdetail/components/OrderCommonLog/OrderCommonLog";
import OrderDetails from "apps/orderdetail/components/OrderDetails/OrderDetails";
import OrderTotal from "apps/orderdetail/components/OrderTotal/OrderTotal";
import OrderDocument from "apps/orderdetail/components/OrderDocument/OrderDocument";
const ItemsSection = lazy(() => import("apps/orderdetail/components/OrderItems/OrderItemsSection"));
const Shipments = lazy(() => import("apps/orderdetail/components/Shipments/Shipments"));
const OrderInvoices = lazy(() => import("apps/orderdetail/components/OrderInvoices/OrderInvoices"));
const InternalNote = lazy(() => import("apps/orderdetail/components/InternalNote/InternalNote"));
const OrderShortcuts = lazy(() => import("apps/orderdetail/components/KeyboardShortcut"));

import "../../components/OrderDocument/orderDocuments.scss";
import "../../styles/orderDetail.scss";
import { useGetInvoices } from "./hook/useGetInvoices";

const transformData = (data, mappings) => {
  return useMemo(() => {
    if (!data?.data?.length) return [];

    return data.data.map((ele) => ({
      description: ele[mappings.description],
      uploaderName: ele[mappings.uploaderName],
      createTime: ele[mappings.createTime],
      personId: ele?.[mappings.handlerId] || 0,
    }));
  }, [data]);
};

const processInvoices = (invoiceRaw, isLoading, isError, type) => {
  if (isLoading || isError) return [];
  return invoiceRaw.invoices
    .filter((ele) => ele.masInvoiceType === type)
    .map((ele) => ({
      date: ele.createDate,
      invoiceNumber: ele.customerInvoiceID,
      referenceNumber: ele.invoiceRefNumQBO,
      totalAmount: ele.invoiceTotal,
      taxAmount: ele.taxFee,
      feeAmount: ele.shipFee,
      downloadUrl: ele.invoiceDoc,
    }))
    .sort((invoiceA, invoiceB) => new Date(invoiceA.date).getTime() - new Date(invoiceB.date).getTime());
};

const ViewOrderDetails = () => {
  const { orderId } = useParams();
  const distributor = useSelector(distributorData);
  const { httpsUrl } = distributor;

  //hooks to fetch order data
  const {
    data: orderData,
    isLoading: isOrderDetailsLoading,
    isError: isOrderDetailsError,
  } = useGetOrderDetails({ orderId });
  const {
    data: statusLogsRaw,
    isLoading: isStatusLogsLoading,
    isError: isStatusLogsError,
  } = useGetStatusLogs(orderId);
  const {
    data: internalNotesRaw,
    isLoading: isInternalNoteLoading,
    isError: isInternalNoteError,
  } = useGetInternalNotes(orderId);
  const {
    data: messagesRaw,
    isLoading: isMessagesLoading,
    isError: isMessagesError,
  } = useGetMessage(orderId);
  const {
    data: invoiceRaw,
    isLoading: isInvoicesLoading,
    isError: isInvoicesError
  } = useGetInvoices(orderId)

  //states
  const [activeSection, setActiveSection] = useState("overview");
  const [sendEmail, setSendEmail] = useState<boolean>();
  const [orderDeliveryProof, setOrderDeliveryProof] = useState();
  const [orderShowUnitPrice, setOrderShowUnitPrice] = useState();
  const [orderShowQRCodeOnPS, setOrderShowQRCodeOnPS] = useState();
  const [customerDeliveryProof, setCustomerDeliveryProof] = useState();
  const [isArchived, setIsArchived] = useState();

  //transform the data
  const internalNotes = transformData(internalNotesRaw, {
    description: "notes",
    uploaderName: "handler",
    createTime: "createDate",
    handlerId: "handlerID",
  });

  const statusLogs = transformData(statusLogsRaw, {
    description: "message",
    uploaderName: "posterEmail",
    createTime: "postDate",
    handlerId: "posterID",
  });

  const messages = transformData(messagesRaw, {
    description: "body",
    uploaderName: "sender",
    createTime: "sentDate",
    handlerId: "fromID",
  });

  const invoices = useMemo(() =>
    processInvoices(invoiceRaw, isInvoicesLoading, isInvoicesError, "IN"),
    [invoiceRaw, isInvoicesLoading, isInvoicesError]
  );

  const creditMemos = useMemo(() =>
    processInvoices(invoiceRaw, isInvoicesLoading, isInvoicesError, "CM"),
    [invoiceRaw, isInvoicesLoading, isInvoicesError]
  );

  const toIdForMessageRedirection = useMemo(
    () => messagesRaw?.data?.[0]?.toID || null,
    [messagesRaw]
  );

  //effects
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100; // Offset for better accuracy
      navItems.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          const offsetTop = element.offsetTop;
          const offsetBottom = offsetTop + element.clientHeight;
          if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
            setActiveSection(section.id);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (orderData) {
      setIsArchived(orderData.isArchived);
      setSendEmail(!orderData.buyer.noEmailFlag);

      setOrderDeliveryProof(orderData.orderProofOfDeliveryFlag);
      setOrderShowUnitPrice(orderData.showUnitPriceOnInvoiceFlag);
      setOrderShowQRCodeOnPS(orderData.showQRCodeOnPackingSlip);

      setCustomerDeliveryProof(orderData.billing.payerProofOfDeliveryFlag);
    }
  }, [orderData]);

  const handleScroll = useCallback((id, behavior: any = "instant", cb) => {
    const section = document.getElementById(id);
    if (!section) return;
    section.scrollIntoView({ behavior: behavior });
    setActiveSection(id);
    if (cb && typeof cb === "function") cb();
  }, [])

  //const url
  const hasToId = toIdForMessageRedirection ? `&toID=${toIdForMessageRedirection}` : "";
  const redirectSendMessageToCust = `${httpsUrl}/manager/mOrder_SendMsgToCust?orderID=${orderId}${hasToId}&mot=1`;
  const redirectToAddNotes = `${httpsUrl}/manager/mOrder_AddNotes?orderID=${orderId}`;

  return (
    <>
      <div className="order-details-wrapper">
        <Suspense fallback={null}>
          <OrderShortcuts orderID={orderId} />
        </Suspense>
        <div className={`${isArchived ? "archived-wrapper" : ""}`}>
          {isArchived ? (
            <>
              <div className="archived-tape">
                <p className="archived-label">
                  <span className="sr-only">This order is </span>Archived
                </p>
              </div>
              <div className="archived-paper">
                <img src="paper.png" />
              </div>
            </>
          ) : null}
        </div>
        <div className={`${isArchived ? "order-detail-main-archived" : ""} order-detail-main`}>
          <Nav
            className="order-detail-nav"
            handleScroll={handleScroll}
            activeSection={activeSection}
            orderId={orderId}
            orderData={orderData}
            loading={isOrderDetailsLoading || isOrderDetailsError}
          />
          <div className="order-detail-column">
            <OrderDetails
              orderId={orderId}
              orderData={orderData}
              loading={isOrderDetailsLoading || isOrderDetailsError}
            />
            <CustomerDetails
              orderId={orderId}
              handleScroll={handleScroll}
              details={orderData}
              isSendEmail={sendEmail}
              showUnitPrice={orderShowUnitPrice}
              isDeliveryProof={customerDeliveryProof}
              loading={isOrderDetailsLoading || isOrderDetailsError}
            />
          </div>
          <div className="order-totals-group">
            <Suspense fallback={<div></div>}>
              <InternalNote orderId={orderId} />
            </Suspense>
            <OrderTotal
              orderId={orderId}
              orderData={orderData}
              loading={isOrderDetailsLoading || isOrderDetailsError}
            />
          </div>
          <Overview
            orderId={orderId}
            orderData={orderData}
            loading={isOrderDetailsLoading || isOrderDetailsError}
          />

          <OrderCommonLog
            uniqueDocuments={messages}
            handleSection={redirectSendMessageToCust}
            loading={isMessagesLoading}
            error={isMessagesError}
            title="Message"
            btnLabel="New Message"
            btnIcon="add"
            id="messages"
            showButton
          />

          <OrderCommonLog
            uniqueDocuments={internalNotes}
            loading={isInternalNoteLoading}
            error={isInternalNoteError}
            handleSection={redirectToAddNotes}
            title="Note"
            btnLabel="New Note"
            btnIcon="mode_comment"
            id="notes"
            showButton
          />

          <OrderCommonLog
            uniqueDocuments={statusLogs}
            loading={isStatusLogsLoading}
            error={isStatusLogsError}
            title="Status Log"
            btnLabel="New Message"
            btnIcon="add"
            id="statusLog"
          />

          <div className="order-items">
            <Suspense fallback={<div></div>}>
              <ItemsSection orderId={orderId} />
            </Suspense>
          </div>
          <OrderDocument orderId={orderId} />
          <div className="order-shipments">
            <Suspense fallback={<div></div>}>
              <Shipments
                orderId={orderId}
              />
            </Suspense>
          </div>
          {orderData && !orderData.showQRCodeOnPackingSlip && <div className="order-invoices">
            <Suspense fallback={<div></div>}>
              <OrderInvoices
                orderId={orderId}
                title="Invoice"
                id="invoice"
                list={invoices}
                totalAmount={invoiceRaw?.invoiceSummary?.totalInvoiceAmount}
                totalTax={invoiceRaw?.invoiceSummary?.totalInvoiceTaxFee}
                totalShipping={invoiceRaw?.invoiceSummary?.totalInvoiceShippingFee}
              />
            </Suspense>
          </div>}

          {orderData && !orderData.showQRCodeOnPackingSlip && <div className="credit-memos">
            <Suspense fallback={<div></div>}>
              <OrderInvoices
                orderId={orderId}
                title="Credit Memo"
                id="creditMemos"
                list={creditMemos}
                totalAmount={invoiceRaw?.invoiceSummary?.totalCreditAmount}
                totalTax={invoiceRaw?.invoiceSummary?.totalCreditTaxFee}
                totalShipping={invoiceRaw?.invoiceSummary?.totalCreditShippingFee}
              />
            </Suspense>
          </div>}
          <OrderManageSection
            orderId={orderId}
            orderData={orderData}
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            showUnitPrice={orderShowUnitPrice}
            setShowUnitPrice={setOrderShowUnitPrice}
            deliveryProof={orderDeliveryProof}
            setDeliveryProof={setOrderDeliveryProof}
            showQRCode={orderShowQRCodeOnPS}
            setShowQRCode={setOrderShowQRCodeOnPS}
            loading={isOrderDetailsLoading || isOrderDetailsError}
          />
        </div>
      </div>
    </>
  );
};

export default ViewOrderDetails;
