import { useQuery } from "@tanstack/react-query";
import { getOrderDetails } from "./service";

export const useGetOrderDetails = (payload) => {
    const { isError, isLoading, isSuccess, data } = useQuery(
        ["get-order-details", payload],
        async () => {
            return getOrderDetails(payload);
        },
        {
            retry: false,
        }
    );

    return {
        isError,
        isLoading,
        isSuccess,
        data,
    };
};
