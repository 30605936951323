import { memo } from "react";
import CustomerDetailsGroup from "./customerDetailsGroup";

import { customerId, labels } from "apps/orderdetail/helper/commonConstants";
import CustomerDetailsLoader from "./customerDetailsLoader";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import Tooltip from "../Tooltip";

const CustomerDetails = ({
  orderId,
  details = {},
  isSendEmail,
  showUnitPrice,
  isDeliveryProof,
  handleScroll,
  loading,
}: any) => {
  const {
    ordererId,
    custCompanyID,
    custFirstName,
    custLastName,
    custCompanyName,
    custEmail,
    custPhone1,
    custPhone2,
    custPhone3,
    custStreet,
    custState,
    custZip,
  } = details.buyer || {};
  const {
    billCompanyName,
    billEmail,
    billFirstName,
    billLastName,
    billPhone1,
    billPhone2,
    billPhone3,
    billState,
    billStreet,
    billZip,
    accountingPackageName,
    billCompanyID
  } = details.billing || {};
  const {
    shipCompanyName,
    shipEmail,
    shipFirstName,
    shipLastName,
    shipPhone1,
    shipPhone2,
    shipPhone3,
    shipState,
    shipStreet,
    shipZip,
    partialShipFlag,
    recipientCompanyTaxExempt,
  } = details.shipping || {};

  const HIGHLIGHT_CLASSNAME = "order-manage-line-highlighted";
  const highlightManageLine = (name) => {
    if (!name) return;
    const line = document.querySelector(`[data-manage-line='${name}']`);
    if (!line) return;
    const switchBtn = line.querySelector(".switch-track") as HTMLElement;
    if (switchBtn) switchBtn.focus();
    if (switchBtn) setTimeout(() => switchBtn.blur(), 10);
    line.classList.add(HIGHLIGHT_CLASSNAME);
    // we add the class and immediately remove it to toggle the highlight
    setTimeout(() => {
      line.classList.remove(HIGHLIGHT_CLASSNAME);
    }, 1e3);
  };

  if (loading) {
    return <CustomerDetailsLoader />;
  }

  return (
    <div className="order-customer-detail">
      <div className="order-customer-detail-section border-top border-primary">
        <CustomerDetailsGroup
          label={labels.customer}
          name={`${custFirstName} ${custLastName}`}
          company={custCompanyName}
          email={custEmail}
          phone={`(${custPhone1}) ${custPhone2}-${custPhone3}`}
          address={`${custStreet}, ${custState}, ${custZip}`}
          isCustomer={true}
          ordererId={ordererId}
          orderId={orderId}
          custCompanyID={custCompanyID}
          isSendEmail={isSendEmail}
          partialShipFlag={partialShipFlag}
        />
        <div className="flex-gap-1 flex-col">
          <Tooltip title={!isSendEmail ? `${custCompanyName} ${labels.hasOptOutMail}` : ""}>
            <button
              className="customer-detail-group text-primary interactive"
              onClick={() => {
                handleScroll(customerId);
                highlightManageLine(`send-email`);
              }}
            >
              <MaterialIcon
                icon={isSendEmail ? "email" : "mail_outline"}
                size="16"
              />
              <p className="p2">
                {isSendEmail ? labels.emailAllowed : labels.emailOptOut}
              </p>
            </button>
          </Tooltip>
          {!showUnitPrice ? (
            <button
              className="customer-detail-group text-primary interactive"
              onClick={() => {
                handleScroll(customerId);
                highlightManageLine(`show-unit-price`);
              }}
            >
              <MaterialIcon icon={"receipt"} size="16" />
              <p className="p2">{labels.unitPriceHiddenOnInvoice}</p>
            </button>
          ) : null}
        </div>
      </div>

      <div className="order-customer-detail-section border-top border-primary">
        <CustomerDetailsGroup
          label={labels.billedTo}
          name={`${billFirstName} ${billLastName}`}
          company={billCompanyName}
          email={billEmail}
          phone={`(${billPhone1}) ${billPhone2}-${billPhone3}`}
          address={`${billStreet}, ${billState}, ${billZip}`}
          ordererId={ordererId}
          orderId={orderId}
          custCompanyID={custCompanyID}
        />
        <div className="flex-gap-2 flex-col">
          {accountingPackageName ? <div className="p2 truncate text-secondary whitespace-normal word-break">
            {labels.masLabel}&nbsp;{billCompanyID}
          </div> : <></>}
          <button
            className="customer-detail-group text-primary interactive"
            onClick={() => {
              // handleScroll(customerId);
              // highlightManageLine(`require-delivery-proof`);
              return;
            }}
          >
            <MaterialIcon
              icon={isDeliveryProof ? "task" : "description"}
              size="16"
            />
            <p className="p2">
              {isDeliveryProof
                ? labels.deliveryProofRequire
                : labels.deliveryProofNotRequire}
            </p>
          </button>
        </div>
      </div>

      <div className="order-customer-detail-section border-top border-primary">
        <CustomerDetailsGroup
          label={labels.shippingTo}
          name={`${shipFirstName} ${shipLastName}`}
          company={shipCompanyName}
          email={shipEmail}
          phone={`(${shipPhone1}) ${shipPhone2}-${shipPhone3}`}
          address={`${shipStreet}, ${shipState}, ${shipZip}`}
          ordererId={ordererId}
          orderId={orderId}
          custCompanyID={custCompanyID}
        />
        <div className="flex-gap-1 flex-col">
          {recipientCompanyTaxExempt ? (
            <div className="partially-shippable">
              <div className="d-flex align-items-center gap-1 text-primary">
                <MaterialIcon icon="money_off" size="16" />
                <p className="p2">{labels.taxExempt}</p>
              </div>
            </div>
          ) : null}
          {partialShipFlag ? (
            <div className="partially-shippable">
              <div className="d-flex align-items-center gap-1 text-primary">
                <MaterialIcon icon="local_shipping" size="16" />
                <p className="p2">{labels.partiallyShippable}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(CustomerDetails);
