import { getOrderDocumentData } from "./service";
import { useQuery } from "@tanstack/react-query";

export const useOrderDocument = (payload) => {
  const { isError, isLoading, isSuccess, data } = useQuery(
    ["get-order-documents", payload],
    async () => {
      return getOrderDocumentData(payload);
    },
    {
      retry: false,
    }
  );

  return {
    isDocumentLoading: isLoading,
    documentData: data?.data ?? [],
    error: isError,
    isSuccess,
  };
};
