import React, { useState, Suspense, lazy } from 'react'
import "./PersonComp.scss"
import Avvvatars from "avvvatars-react";
const HoverProjectManage = lazy(() => import("../Overview/HoverProjectManager"));

const PersonComp = (({ personId, personName }) => {
    const avatarBgColor = '#fff';
    const [apiPersonName, setApiPersonName] = useState(personName)

    const hoverComponent = (
        <Suspense fallback={<div>Loading...</div>}>
            <HoverProjectManage personId={personId} setApiPersonName={setApiPersonName} />
        </Suspense>
    )

    return (
        <>
            <button
                className="reset-button person-btn"
                id="dropdownMenuODButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span className="project-dot">
                    <Avvvatars
                        value={apiPersonName}
                        displayValue={apiPersonName?.match(/\b\w/g).join("").toUpperCase() || ""}
                        size={18}
                        border={true}
                        borderSize={2}
                        borderColor={avatarBgColor}
                    />
                </span>
                <div>
                    {apiPersonName}
                </div>
            </button>
            <div
                className="dropdown-menu py-3 px-4 new-order-detail"
                aria-labelledby="dropdownMenuODButton"
                onClick={(e) => e.stopPropagation()}
            >
                {hoverComponent}
            </div>
        </>
    )
})

export default PersonComp