import "./Switch.scss";

interface SwitchProps {
  value: boolean;
  onChange?: () => void;
  id?: string;
  disabled?: boolean;
}

const Switch = ({ value, onChange, id, disabled }: SwitchProps) => {
  return (
    <button
      id={id}
      onClick={onChange}
      disabled={disabled}
      aria-pressed={value}
      className={`switch-track ${value ? "switch-on" : ""}`}
    >
      <span className="switch-thumb"></span>
      <span className="switch-status" aria-live="polite">
        {value ? "On" : "Off"}
      </span>
    </button>
  );
};

export default Switch;
