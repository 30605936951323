import "./Icon.scss";

const Icon = (props) => {
  const { size = 24, name = "help", className = "" } = props;

  return (
    <span
      className={`order-details-icon ${className}`}
      style={{ "--size": size }}
    >
      {name}
    </span>
  );
};

export default Icon;
