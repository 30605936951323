import { memo } from 'react'
import { TotalCalculationBlock } from '../TotalCalculationBlock/TotalCalculationBlock';
import SvgBarChart from '../BarChart/svgBarChart';
import CardLine from '../CardLine/cardLine';
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";
// import OrderTotalLoader from './OrderTotalLoader';
import { formatCurrency } from 'utils/numberFormat';
import { Divider } from "@material-ui/core"
import { ORDER_CONFIG, ORDER_RECEIVED_CONFIG, labels } from 'apps/orderdetail/helper/commonConstants';
import { orderTotalType } from 'apps/orderdetail/helper/types';
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton"

const HandleLoading = (({ loading, children, height, width }) => {
    return (
        <>
            {loading ?
                <ShapedSkeleton
                    width={width}
                    height={height}
                    shape={`0 0 ${width} ${height}`}
                    radius={4}
                    fullWidth={false}
                /> : children}
        </>
    )
})


const OrderTotal = ({ orderId, orderData = {}, loading }: any) => {
    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;
    const changeRequiredDepositeUrl = `${httpsUrl}/manager/mOrder_updateDepositPercent.aspx?orderID=${orderId}`

    const { changeOrderMarginPercent, initialMarginPercent, requiredDepositPercent, totalAmount, totInvoicedAmt, totPaymentReceived, totValueInStorage, profit, receivedDeposite } = orderData

    const pendingAmount = totalAmount - totInvoicedAmt - totValueInStorage;

    const orderTotal: orderTotalType[] = ORDER_CONFIG.map(config => ({
        label: config.label,
        value: config.key === "pendingAmount" ? pendingAmount : orderData[config.key],
        color: config.color,
        key: config.key
    }));

    let orderReceived: orderTotalType[] = ORDER_RECEIVED_CONFIG.map(config => ({
        label: config.label,
        value: orderData[config.key],
        color: config.color,
        key: config.key
    }));
    orderReceived = totPaymentReceived === totInvoicedAmt ? [orderReceived[0]] : orderReceived;

    const expectDeposit = (totalAmount * requiredDepositPercent) / 100;
    const pendingRequiredDeposit = expectDeposit - receivedDeposite
    const orderDepositeTotal: orderTotalType[] = [
        { label: "Received", value: receivedDeposite, color: "bg-purple-primary", key: "receivedDeposite" },
        { label: "Pending", value: pendingRequiredDeposit, color: "bg-purple-tertiary", key: "pendingRequiredDeposit" },
    ];

    // if (loading) {
    //     return <OrderTotalLoader />
    // }

    return (
        <>
            <div className="order-total-container">
                <div className="flex-gap-3 card">
                    <div className="d-flex flex-column">
                        <p className="subtitle3 text-secondary">{labels.orderTotal}</p>
                        <HandleLoading loading={loading} width={"170"} height={30}>
                            <p className={`h1 ${totalAmount ? "text-primary" : "text-tertiary"}`}>{formatCurrency(totalAmount)}</p>
                        </HandleLoading>

                    </div>
                    {
                        totalAmount || loading ?
                            <>
                                <HandleLoading loading={loading} width={"380"} height={24}>
                                    <SvgBarChart
                                        data={orderTotal}
                                        parentClass="rounded-md h-6"
                                    />
                                </HandleLoading>
                                <ul className="d-flex flex-column">
                                    <HandleLoading loading={loading} width={"380"} height={16}>
                                        <CardLine
                                            label="Invoiced"
                                            value={formatCurrency(totInvoicedAmt)}
                                            color="bg-green-primary"
                                        />
                                    </HandleLoading>
                                    {pendingAmount ? <CardLine
                                        label="Pending"
                                        value={formatCurrency(pendingAmount < 0 ? 0 : pendingAmount)}
                                        color="bg-surface-tertiary"
                                    /> : null}

                                    {totValueInStorage ? (
                                        <CardLine
                                            label="Stored"
                                            value={formatCurrency(totValueInStorage)}
                                            color="bg-indigo-primary"
                                        />
                                    ) : null}

                                </ul>
                                {totInvoicedAmt || loading ? (
                                    <>
                                        <Divider className='divider-opacity' />
                                        <div className='flex-gap-3 flex-column'>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <span className="order-total-container-label text-start">
                                                        Received
                                                    </span>
                                                    <HandleLoading loading={loading} width={"110"} height={32}>
                                                        <span className="order-total-container-value">
                                                            {formatCurrency(totPaymentReceived)}
                                                        </span>
                                                    </HandleLoading>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <span className="order-total-container-label text-end">
                                                        Invoiced
                                                    </span>
                                                    <HandleLoading loading={loading} width={"110"} height={32}>
                                                        <span className="order-total-container-value">
                                                            {formatCurrency(totInvoicedAmt)}
                                                        </span>
                                                    </HandleLoading>
                                                </div>
                                            </div>
                                            <HandleLoading loading={loading} width={"380"} height={6}>
                                                <SvgBarChart
                                                    data={orderReceived}
                                                    customClassName="order-total-container-bar"
                                                    parentClass="rounded"
                                                />
                                            </HandleLoading>
                                        </div>
                                    </>
                                ) : null}
                            </>
                            : <></>
                    }
                </div >
            </div >
            <div className="order-total-container">
                <div className="flex-gap-3 card">
                    <div className="d-flex justify-content-between align-items-start">
                        <div>
                            <p className="subtitle3 text-secondary">Required Deposit</p>
                            <HandleLoading loading={loading} width={"170"} height={30}>
                                <div className="d-flex  report-deposit-percent-gap">
                                    <p className={`h1 ${expectDeposit ? "text-primary" : "text-tertiary"}`}>{formatCurrency(expectDeposit)}</p>
                                    {expectDeposit ? <p className="text-secondary align-self-end report-deposit-percent p2">
                                        {requiredDepositPercent}%
                                    </p> : <></>}
                                </div>
                            </HandleLoading>
                        </div>
                        <a href={changeRequiredDepositeUrl} target='_blank' className="btn od-btn-secondary interactive">
                            <span>Change</span>
                        </a>
                    </div>
                    {
                        expectDeposit || loading ?
                            <>
                                <HandleLoading loading={loading} width={"380"} height={24}>
                                    <SvgBarChart
                                        data={orderDepositeTotal}
                                        parentClass="rounded-md h-6"
                                    />
                                </HandleLoading>
                                <ul className="d-flex flex-column">
                                    <HandleLoading loading={loading} width={"380"} height={16}>
                                        {receivedDeposite ? (
                                            <CardLine
                                                label="Received"
                                                value={formatCurrency(receivedDeposite)}
                                                color="bg-purple-primary"
                                            />
                                        ) : null}
                                    </HandleLoading>
                                    <HandleLoading loading={loading} width={"380"} height={16}>
                                        {pendingRequiredDeposit ? <CardLine
                                            label="Pending"
                                            value={formatCurrency(pendingRequiredDeposit)}
                                            color="bg-purple-tertiary"
                                        /> : null}
                                    </HandleLoading>
                                </ul>
                            </> : <></>}
                </div>
            </div >

            <TotalCalculationBlock
                orderId={orderId}
                changeOrderMarginPercent={changeOrderMarginPercent}
                initialMarginPercent={initialMarginPercent}
                profitData={profit}
                loading={loading}
            />
        </>
    )
}

export default memo(OrderTotal)