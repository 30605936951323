import { Divider, Grid } from "@material-ui/core";
import Avvvatars from "avvvatars-react";
import { useSelector } from "react-redux";
import { distributorData } from "sagas/distributor/selector";
import ReportDetail from "./reportDetail";
import { useGetCustomerDetail } from "apps/orderdetail/modules/orderDetailsContainer/hook/useGetCustomerDetails";
import { getRelativeTime, isValidDate, formatDate } from "utils/utils";
import { useMemo } from "react";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import SPButton from "components/SPButton";
import { formatCurrency } from "utils/numberFormat";
import { labels } from "apps/orderdetail/helper/commonConstants";

const getReturnRateCategory = (returnRate) => {
    if (returnRate === 0) return "None";

    const categories = ["Very low", "Low", "Medium", "High", "Very High"];
    const index = Math.min(Math.floor(returnRate / 20), categories.length - 1);

    return categories[index];
};


const CustomerOverview = (props) => {
    const avatarBgColor = '#fff';

    const { name, company, ordererId, orderId, label, isSendEmail /*, partialShipFlag*/ } = props;

    const { data: customerData, isLoading, isError } = useGetCustomerDetail(props.custCompanyID);
    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;

    const viewOrdersUrl = `${httpsUrl}/manager/mCust_orderList?personID=${ordererId}`;
    const newMessageUrl = `${httpsUrl}/manager/mOrder_SendMsgToCust?orderID=${orderId}&toID=${ordererId}`
    const editUrl = `${httpsUrl}/manager/mCust_UpdatePerson.aspx?registered=1&orderID=${orderId}&personID=${ordererId}`

    const { noMailFlag, showPrices } = customerData || {};

    const displayData = useMemo(() => {
        if (!isLoading && !isError) {
            const { lastOrderDate, customerSinceDate } = customerData

            const lastOrderDisplayDate = isValidDate(lastOrderDate) ? formatDate(lastOrderDate) : lastOrderDate;
            const lastOrderDisplayDay = !isValidDate(lastOrderDate) ? "" : getRelativeTime(lastOrderDisplayDate)
            const customerSinceDisplayDate = isValidDate(customerSinceDate) ? formatDate(customerSinceDate) : customerSinceDate;
            const customerSinceDisplayYear = getRelativeTime(customerSinceDate)

            return {
                lastOrderDisplayDay,
                lastOrderDisplayDate,
                customerSinceDisplayDate,
                customerSinceDisplayYear
            }
        }
    }, [customerData, isLoading, isError]);


    const { lifetimeValue, avgOrderValue, returnRate, } = customerData || {};
    const { lastOrderDisplayDay, lastOrderDisplayDate, customerSinceDisplayDate, customerSinceDisplayYear } = displayData || {}

    return (
        <>
            <button
                className="order-detail-placed-customer-title interactive text-primary"
                id="dropdownMenuODButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className="d-flex flex-column">
                    {label ? <p className="subtitle3 text-primary text-align-left">{label}</p> : null}
                    {name ? (
                        <div className="d-flex align-items-center gap-3 text-primary">
                            <p className="subtitle1 truncate whitespace-normal word-break text-align-left">{name}</p>
                        </div>
                    ) : null}
                    {company ? <p className="p2 text-primary text-align-left whitespace-normal word-break">{company}</p> : null}
                </div>
            </button>
            <div
                className="dropdown-menu customer-popup-border-radius"
                aria-labelledby="dropdownMenuODButton"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="customer-overview-popup">
                    <div className="customer-popup-info">
                        <div className="flex-gap-10 space-x-4 align-items-center">
                            <Avvvatars
                                value={name}
                                displayValue={name.match(/\b\w/g).join("").toUpperCase()}
                                size={50}
                                border={true}
                                borderSize={2}
                                borderColor={avatarBgColor}
                            />
                            <div className="d-flex flex-column gap-1">
                                <p className="subtitle1 text-primary whitespace-normal word-break">{name}</p>
                                <p className="p3 text-primary whitespace-normal word-break">{company}</p>
                            </div>
                        </div>
                        <SPButton label="Edit" customClassName="customer-popup-edit-btn" href={editUrl} target="_blank" />
                    </div>
                    <div className="flex-gap-1 flex-col">
                        {!noMailFlag ? <div className="flex-gap-1 text-primary">
                            <MaterialIcon icon={isSendEmail ? "email" : "mail_outline"} size="16" />
                            <p className="p2">{isSendEmail ? labels.emailAllowed : labels.emailOptOut}</p>
                        </div> : null}
                        {showPrices ? <div className="flex-gap-1 text-primary">
                            <MaterialIcon icon={"receipt"} size="16" />
                            <p className="p2">{labels.showUnitPriceCustomerLevel}</p>
                        </div> : null}
                        {/* {partialShipFlag ? (
                            <div className="flex-gap-1 text-primary">
                                <MaterialIcon icon="local_shipping" size="16" />
                                <p className="p2">{labels.partiallyShippable}</p>
                            </div>
                        ) : null} */}
                    </div>
                    <div className="flex-gap-4">
                        <a className="btn od-btn-secondary od-btn-mid interactive" href={viewOrdersUrl} target="_blank">
                            <span>See Orders</span>
                        </a>
                        <a className="btn od-btn-secondary od-btn-mid interactive" href={newMessageUrl} target="_blank" >
                            <span>New Message</span>
                        </a>
                    </div >
                    <Divider className="divider-opacity" />
                    <Grid container className="order-detail-report-container">
                        <Grid
                            container
                            className="px-0"
                        >
                            <Grid item xs={12} sm={6}>
                                <div className="d-flex flex-column gap-1">
                                    <span className="subtitle3">Lifetime Value</span>
                                    <span className={`h3 ${lifetimeValue ? "" : "text-tertiary"}`}>{lifetimeValue ? formatCurrency(lifetimeValue) : "-"}</span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="d-flex flex-column gap-1">
                                    <span className="subtitle3">Total orders</span>
                                    <span className={`h3 ${avgOrderValue ? "" : "text-tertiary"}`}>{avgOrderValue ? avgOrderValue : "-"}</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className="divider-opacity" />
                    <section className="order-detail-report-section customer-overview-bottom-details">
                        <Grid container className="order-detail-report-container ">
                            <ReportDetail
                                label="Last Order"
                                primaryText={lastOrderDisplayDate}
                                secondaryText={lastOrderDisplayDay}
                                className="px-0 py-0"
                            />
                        </Grid>
                        <Grid container className="order-detail-report-container  px-0">
                            <ReportDetail
                                label="Return Rate"
                                primaryText={getReturnRateCategory(returnRate)}
                                className="px-0 py-0"
                            />

                        </Grid>
                        <Grid container className="order-detail-report-container  px-0">
                            <ReportDetail
                                label="Customer since"
                                primaryText={customerSinceDisplayDate}
                                secondaryText={customerSinceDisplayYear}
                                className="px-0 py-0"
                            />
                        </Grid>
                    </section>
                </div>
            </div >
        </>
    )

}

export default CustomerOverview;