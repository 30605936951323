import "@fontsource/material-icons-rounded"
import styles from "./MaterialIcon.module.scss"

interface MaterialIconProps {
    classes?: string;
    icon: any;
    size?: string;
    customeClass?: string;
}

export default function MaterialIcon(props: MaterialIconProps) {
    const { icon, classes, size = 24, customeClass = "" } = props;
    return (
        <span
            className={`${customeClass} ${styles["material-icon"]} ${classes ? classes : ''}`}
            style={{ '--size': `${size}` } as React.CSSProperties}
        >
            {icon}
        </span>
    )
}
