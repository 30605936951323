export const navItems = [
  {
    id: "overview",
    label: "Overview",
    icon: "explore",
    shortcut: "o",
    displayForStockOrder: true,
  },
  {
    id: "messages",
    label: "Messages",
    icon: "drafts",
    shortcut: "m",
    displayForStockOrder: true,
  },
  {
    id: "notes",
    label: "Notes",
    icon: "mode_comment",
    shortcut: "n",
    displayForStockOrder: true,
  },
  {
    id: "statusLog",
    label: "Status Log",
    icon: "history",
    shortcut: "l",
    displayForStockOrder: true,
  },
  {
    id: "items",
    label: "Items",
    icon: "workspaces",
    shortcut: "i",
    displayForStockOrder: true,
  },
  {
    id: "documents",
    label: "Documents",
    icon: "folder_open",
    shortcut: "d",
    displayForStockOrder: true,
  },
  {
    id: "shipments",
    label: "Shipments",
    icon: "local_shipping",
    shortcut: "s",
    displayForStockOrder: true,
  },
  {
    id: "invoice",
    label: "Invoices",
    icon: "receipt",
    shortcut: "v",
    displayForStockOrder: false,
  },
  {
    id: "creditMemos",
    label: "Credit Memos",
    icon: "sticky_note_2",
    shortcut: "c",
    displayForStockOrder: false,
  },
  {
    id: "manage",
    label: "Manage",
    icon: "rule",
    shortcut: "a",
    displayForStockOrder: true,
  },
  {
    id: "rmas",
    label: "RMAs",
    icon: "replay_circle_filled",
    shortcut: "r",
    displayForStockOrder: false,
  },
  {
    id: "tickets",
    label: "Tickets",
    icon: "assignment_turned_in",
    shortcut: "t",
    displayForStockOrder: true,
    href: "{baseUrl}/tickets/msTicket_by_target.aspx?targetTypeID=2&targetID={orderId}",
  },
  {
    id: "actions",
    label: "Actions",
    icon: "ads_click",
    shortcut: "",
    displayForStockOrder: true,
    href: "{baseUrl}/manager/mOrder_actions?orderID={orderId}",
  },
];

export const sectionName = {
  OverviewSection: "Overview",
  MessageSection: "Messages",
  ItemsSection: "Items",
  NotesSection: "Notes",
  StatusLogSection: "Status Log",
  DocumentsSection: "Documents",
  ShipmentsSection: "Shipments",
  RmasSection: "RMAs",
  SettingsSection: "Settings",
  ManageSection: "Manage",
  TicketSection: "Tickets",
};

export const orderStatusValue = {
  placed: "Order Placed",
  inProcess: "In Process (partially dispatched)",
  processed: "Processed",
  inStorage: "In Storage",
  shipped: "Shipped",
  hold: "Hold",
  cancelled: "Cancelled",
  unusual: "Unusual",
};

export const orderStatusStyles = {
  [orderStatusValue.placed]: { color: "#ebf9ee", className: "placed-status", tooltipMessage: "Order approved, no action taken yet" },
  [orderStatusValue.inProcess]: { color: "#027bff", className: "in-process-status", tooltipMessage: "Some items pending action" },
  [orderStatusValue.processed]: { color: "#af52de", className: "processed-status", tooltipMessage: "All items sent to warehouse or vendor" },
  [orderStatusValue.inStorage]: { color: "#5856d7", className: "in-storage-status", tooltipMessage: "" },
  [orderStatusValue.shipped]: { color: "#04c7be", className: "shipped-status", tooltipMessage: "All items physically shipped" },
  [orderStatusValue.hold]: { color: "#fff4e6", className: "hold-status", tooltipMessage: "" },
  [orderStatusValue.cancelled]: { color: "#ffebea", className: "cancelled-status", tooltipMessage: "Order has been cancelled" },
  [orderStatusValue.unusual]: { color: "#ff9500", className: "unusual-status", tooltipMessage: "Order needs approval" },
};

export const projectMangerChanged = "Project manager changed";
export const detachProjectMessage =
  "Order was successfully detached from the Customer Project.";

export const customerId = "manage";

export const ManageSection = {
  holdOrder: "Hold Order",
  putOnAdminHold: "Put on Admin hold",
  holdShipping: "Hold Shipping",
  putOnShippingHold: "Put on Shipping hold",
  actionTakenOnThisOrder: "Actions taken on this order",
  seeActions: "See Actions",
};

export const today = "Today";
export const yesterday = "Yesterday";
export const noOrder = "No order placed yet";

export const productStatusConstant = {
  0: {
    value: 0,
    name: "Out of stock",
    className: "out-of-stock",
  },
  1: {
    value: 1,
    name: "{count} available",
    className: "available",
  },
  2: {
    value: 2,
    name: "Checking inventory...",
    className: "loading",
  },
  3: {
    value: 3,
    name: "Counting inventory...",
    className: "count-inventory",
  },
};

export const orderTypeToIconMaping: Record<string | number, string> = {
  1: "CreditCard",
  3: "Paypal",
  mastercard: "Mastercard",
  visa: "Visa",
  americanexpress: "AmericanExpress",
  discover: "Discover",
  fullypaidcreditcardorder: "FullyPaidCreditCard",
  creditcardorder: "CreditCard",
  6: "Check",
  5: "Cash",
  0: "Registered",
  2: "Prepaid",
  4: "Replacement",
};

export const tooltipTitle = {
  1: "Credit Card Order",
  3: "Paypal Order",
  mastercard: "Mastercard Order",
  visa: "Visa Order",
  americanexpress: "American Express Order",
  discover: "Discover Order",
  fullypaidcreditcardorder: "Fully Paid Credit Card Order",
  creditcardorder: "Credit Card Order",
  6: "Check Order",
  5: "Cash Order",
  0: "Registered Order",
  2: "Prepaid Order",
  4: "Replacement Order",
};

export const labels = {
  notForReleaseLabel: "Not for release",
  viewInvoiceLabel: "View Invoice",
  addCostLabel: "Add Cost",
  createRMACLabel: "Create RMA-C",
  emailAllowed: "Email allowed",
  emailOptOut: "Email opted-out",
  unitPriceHiddenOnInvoice: "Unit price hidden on invoice",
  masLabel: " MAS90 #",
  deliveryProofRequire: " Delivery proof required",
  deliveryProofNotRequire: "Delivery proof not required",
  customer: "Customer",
  billedTo: "Billed to",
  shippingTo: "Shipping to",
  taxExempt: "Tax exempt",
  partiallyShippable: "Partially shippable",
  orderTotal: "Order Total",
  addJobName: "Add Job Name",
  internalNotes: "Internal note",
  showUnitPriceCustomerLevel: "Unit price hidden on invoice for all orders",
  hasOptOutMail: "has opted out of mails"
};

export const ORDER_CONFIG = [
  { label: "Invoiced", key: "totInvoicedAmt", color: "bg-green-primary" },
  { label: "Pending", key: "pendingAmount", color: "bg-surface-tertiary" },
  { label: "Stored", key: "totValueInStorage", color: "bg-indigo-primary" },
];

export const ORDER_RECEIVED_CONFIG = [
  { label: "Received", key: "totPaymentReceived", color: "bg-green-primary" },
  { label: "Invoiced", key: "totInvoicedAmt", color: "bg-surface-tertiary" },
];

export const CHARGES_CONFIG = [
  {
    label: "Initial charge on customer",
    key: "initialChargeOnCustomer",
    display: true,
  },
  {
    label: "Total charge without Tax",
    key: "totalChargeWithoutTax",
    display: true,
  },
  {
    label: "Paid to Vendor on Items",
    key: "paidToVendorOnItems",
    display: true,
  },
  { label: "Credit Issued to Customer", key: "custCreditTotWithTax", display: false },
  { label: "Cost (Shipping)", key: "yourPaidCost", display: true },
  {
    label: "Returned Items in vendor price",
    key: "returnedItemsInVendorPrice",
    display: true,
  },
  {
    label: "Total Cost without Tax",
    key: "totalCostWithoutTax",
    display: true,
  },
  {
    label: "Extra Advertisement Fee",
    key: "extraAdvertisementFee",
    display: false,
  },
  { label: "Extra Consulting Fee", key: "extraConsultingFee", display: false },
  {
    label: "Extra Installation Fee",
    key: "extraInstallationFee",
    display: false,
  },
  { label: "Extra Shipping Fee", key: "extraShippingFee", display: false },
  { label: "Extra Survey Fee", key: "extraSurveyFee", display: false },
  { label: "Extra Storage Fee", key: "extraStorageFee", display: false },
  { label: "Extra Expedite Fee", key: "extraExpediteFee", display: false },
  { label: "Extra Restocking Fee", key: "extraRestockingFee", display: false },
  {
    label: "Extra Cancellation Fee",
    key: "extraCancellationFee",
    display: false,
  },
  {
    label: "Vendor Charged Ship Fee",
    key: "vendorChargedShipFee",
    display: false,
  },
  { label: "Amazon Fee", key: "amazonFee", display: false },
];

export const CHARGES_INFO_CONFIG = [
  { label: "Customer PO Amt.", key: "customerPoAmount", display: true },
  { label: "Difference", key: "difference", display: true },
];

export const ip = {
  notInUSA: "IP Not in USA"
}

export const internalNotesStatus = {
  LOADING: "Loading...",
  SAVING: "Saving...",
  SAVED: "Saved",
  IDLE: "",
};

export const INTERNAL_NOTES_MAX_LENGTH = 200;

export const invoiceHeaders = [
  { text: 'date', className: 'align-text-start' },
  { text: 'mas90 #', className: 'align-text-start' },
  { text: 'qb ref #', className: 'align-text-start' },
  { text: 'amount', className: 'align-text-end' },
  { text: 'tax', className: 'align-text-end' },
  { text: 'ship fee', className: 'align-text-end' },
];
