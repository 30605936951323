import React, { Suspense, useMemo } from "react";
import Tooltip from "../Tooltip";
import {
  orderTypeToIconMaping,
  tooltipTitle,
} from "apps/orderdetail/helper/commonConstants";

interface OrderTypeIconProps {
  orderType: string | number;
  isCreditCardWithType: boolean;
  orderTypeNameLabel: string;
}

const OrderTypeIcon: React.FC<OrderTypeIconProps> = ({
  orderType,
  isCreditCardWithType,
  orderTypeNameLabel,
}) => {
  const IconComponent = useMemo(() => {
    const iconName = orderTypeToIconMaping[orderType];

    if (!iconName) return null;

    try {
      return React.lazy(() => import(`./${iconName}`));
    } catch (error) {
      console.error(`Error loading icon for orderType "${orderType}":`, error);
      return null;
    }
  }, [orderType]);

  if (!IconComponent) return null;

  return (
    <Tooltip title={tooltipTitle[orderType]}>
      <div className="flex-gap-1 align-items-center">
        <span className="order-type-icon">
          <Suspense fallback={<span className="icon-placeholder" />}>
            <IconComponent className="shrink-0 custom-element text-secondary" />
          </Suspense>
        </span>
        {!isCreditCardWithType && <p>{orderTypeNameLabel}</p>}
      </div>
    </Tooltip>
  );
};

export default OrderTypeIcon;
