import { useQuery } from "@tanstack/react-query";
import { getMessage } from "./service";

export const useGetMessage = (payload) => {
    const { isError, isLoading, isSuccess, data } = useQuery(
        ["get-message-details", payload],
        async () => {
            return getMessage(payload);
        },
        {
            retry: false,
        }
    );

    return {
        isError,
        isLoading,
        isSuccess,
        data,
    };
};
