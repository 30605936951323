// detachOverviewProject


import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { detachProjectMessage } from "apps/orderdetail/helper/commonConstants";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { detachOverviewProject } from "./service";

export const useDetachProject = () => {
    const dispatch = useDispatch();
    const { mutateAsync, isLoading, data, error, isSuccess } = useMutation(
        async (payload) => {
            return detachOverviewProject(payload);
        },
        {
            onSuccess(data) {
                if (data) {
                    dispatch(snakbarShow({ message: detachProjectMessage }));
                }
            },
        }
    );

    const detachProject = useCallback(
        (data) => {
            return mutateAsync(data);
        },
        [mutateAsync]
    );

    return {
        detachProject,
        isLoading,
        data,
        error,
        isSuccess,
    };
};
