interface SvgBarChart {
  data: {
    label: string;
    value: number;
    color: string;
    key: string;
  }[];
  customClassName?: string;
  parentClass?: string;
}

const SvgBarChart = ({
  data,
  customClassName,
  parentClass,
}: SvgBarChart) => {
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const minimumWidth = 10;

  return (
    <div className={`${parentClass} d-flex gap-0-5 overflow-hidden`}>
      {data.map((item, index) => {
        const width = (item.value / total) * 100;
        return (
          <div
            key={`${index}-${item.value}`}
            style={{
              width: `${width >= 0 && width < minimumWidth ? minimumWidth : width
                }%`,
            }}
            className={`${customClassName} ${item.color
              } shrink-0 ${width === 0 ? "hidden" : ""}`}
            data-chart-key={item.label}
          />
        );
      })}
    </div>
  );
};

export default SvgBarChart;
