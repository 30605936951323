import { useOrderDocument } from 'apps/orderdetail/modules/orderDetailsContainer/hook/useOrderDocument';
import React, { memo, useState } from 'react';
import { DocumentsWithFilters } from './DocumentsWithFilters';
import { useSelector } from 'react-redux';
import { distributorData } from 'sagas/distributor/selector';
import { PosModel } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";

const OrderDocument = ({ orderId }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const distributor = useSelector(distributorData);
    const { httpsUrl } = distributor;

    const { documentData, isDocumentLoading, error } = useOrderDocument(orderId);
    const uniqueDocuments = documentData.slice(0, 5)

    const extraObjectsLength = documentData.length - 5;

    const openModal = () => setIsModalOpen(true);
    const handleCloseModel = () => setIsModalOpen(false);

    const addNewDocument = `${httpsUrl}/manager/mOrder_DocUpload?orderID=${orderId}`

    return (
        <div className="order-document user-order-details" id="documents" key="documents">
            <div className="order-document-child">
                <DocumentsWithFilters
                    count={documentData.length}
                    uniqueDocuments={uniqueDocuments}
                    loading={isDocumentLoading}
                    handleUpload={addNewDocument}
                    error={error}
                />
            </div>
            {extraObjectsLength > 5 && (
                <div className="view-document">
                    <span>+ {extraObjectsLength} more</span>
                    <span>
                        <button
                            className="btn od-btn-secondary interactive"
                            onClick={openModal}
                        >
                            <span>View all Documents</span>
                        </button>
                    </span>
                </div>
            )}
            <div className="order-document-child-data">
                <PosModel
                    open={isModalOpen}
                    onClose={handleCloseModel}
                    dialogClassName="set-customer-code-modal-container"
                    dialogHeader={
                        <div className="modal-header pl-4">
                            <span className="modal-title">
                                <div className="pt-2">
                                    <h3 className="f20-Inter-500-sab mb-0 mb-0">
                                        {"View all Documents"}
                                    </h3>
                                </div>
                            </span>
                            <IconButton
                                name="close"
                                className="close-icon"
                                type="tertiary"
                                onClick={handleCloseModel}
                            />
                        </div>
                    }
                >
                    <DocumentsWithFilters
                        count={documentData.length}
                        uniqueDocuments={documentData}
                        loading={isDocumentLoading}
                        handleUpload={addNewDocument}
                        error={error}
                    />
                </PosModel>
            </div>
        </div>
    )
}

export default memo(OrderDocument);