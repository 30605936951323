import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

const customerDetailsLoader = () => {
    return (
        <div className="order-customer-detail">
            <div className="order-customer-detail-section border-top border-primary">
                <div>
                    <p className="subtitle3 text-primary">Customer</p>
                    <ShapedSkeleton
                        width={"100%"} // Adjust width to fit the content
                        height={60} // Height to fit the avatar and message
                        shape="
                0 2 160 24,
                0 28 94 16
            "
                        radius={4} // Rounded corners for rectangles
                        fullWidth={false}
                    />
                </div>
                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={33} // Height to fit the avatar and message
                    shape="
                0 0 220 16, 
                0 18 220 16,
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={33} // Height to fit the avatar and message
                    shape="
                0 0 220 16,
                0 18 220 16,
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={20} // Height to fit the avatar and message
                    shape="
                0 0 180 18
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

            </div>
            <div className="order-customer-detail-section border-top border-primary">
                <div>
                    <p className="subtitle3 text-primary">Billed to</p>
                    <ShapedSkeleton
                        width={"100%"} // Adjust width to fit the content
                        height={58} // Height to fit the avatar and message
                        shape="
                0 2 160 24,
                0 28 94 16
            "
                        radius={4} // Rounded corners for rectangles
                        fullWidth={false}
                    />
                </div>

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={32} // Height to fit the avatar and message
                    shape="
                0 0 220 16, 
                0 18 220 16,
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={32} // Height to fit the avatar and message
                    shape="
                0 0 220 16,
                0 18 220 16,
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={18} // Height to fit the avatar and message
                    shape="
                0 0 180 18
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />
            </div>
            <div className="order-customer-detail-section border-top border-primary">
                <div>
                    <p className="subtitle3 text-primary">Shipping to</p>
                    <ShapedSkeleton
                        width={"100%"} // Adjust width to fit the content
                        height={58} // Height to fit the avatar and message
                        shape="
                0 2 160 24,
                0 28 94 16
            "
                        radius={4} // Rounded corners for rectangles
                        fullWidth={false}
                    />
                </div>

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={32} // Height to fit the avatar and message
                    shape="
                0 0 220 16, 
                0 18 220 16,
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={32} // Height to fit the avatar and message
                    shape="
                0 0 220 16,
                0 18 220 16,
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />

                <ShapedSkeleton
                    width={"100%"} // Adjust width to fit the content
                    height={18} // Height to fit the avatar and message
                    shape="
                0 0 180 18
            "
                    radius={4} // Rounded corners for rectangles
                    fullWidth={false}
                />
            </div>
        </div>
    )
}

export default customerDetailsLoader;