import { Grid } from "@material-ui/core";
import "apps/orderdetail/styles/orderDetail.scss";

interface OrderData {
  label: string;
  value: any;
  isBottomBorder?: boolean;
  isEllipsis?: boolean;
}

export const OrderClosedData = ({ label, value, isBottomBorder, isEllipsis }: OrderData) => {
  return (
    <>
      <Grid container className="order-detail-report-container">
        <Grid
          container
          className={`report-div ${isBottomBorder ? "border-bottom" : ""}`}
        >
          <Grid item xs={12} sm={5}>
            <div className="title text-secondary">
              <span>{label}</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className="title text-primary ml-2">
              <span className={` ${isEllipsis ? "word-break" : ""}`}>{value}</span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
