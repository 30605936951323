import { memo, useState } from "react";
import "apps/orderdetail/components/OrderCommonLog/order-manage-note-log.scss";
import { PosModel } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import Title from "../Title/Title";
import PersonComp from "../PersonComp/PersonComp";

import { formatOneWeekRelativeDate } from "utils/utils";

interface OrderCommonLogProps {
  uniqueDocuments: any;
  loading?: boolean;
  error: boolean;
  handleSection?: any;
  title: string;
  showButton?: boolean;
  btnLabel?: string;
  btnIcon?: string;
  id: string;
}

const ListingOrderCommonLog: any = ({ uniqueDocuments }) => {
  return (
    <div className="order-details-data">
      {uniqueDocuments?.map((item: any, index) => {
        return (
          <div className="all-info" key={index}>
            <div className="all-info-description">
              <div
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
            <div className="all-info-username">
              <PersonComp personName={item.uploaderName} personId={item.personId} />
              <span className="time">{formatOneWeekRelativeDate(item.createTime)}</span>
            </div>
          </div>
        );
      })}
    </div>
  )
}


export const OrderCommonLog = memo(({
  uniqueDocuments,
  loading,
  error,
  handleSection,
  title,
  btnLabel,
  btnIcon,
  id
}: OrderCommonLogProps) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const extraObjectsLength = uniqueDocuments.length - 5;

  const openModal = () => setIsModalOpen(true);
  const handleCloseModel = () => setIsModalOpen(false);

  return (
    <div className="order-common-log  user-order-details">
      <div className="order-manage-note-log " id={id} key={id}>
        <Title count={uniqueDocuments?.length} btnIcon={btnIcon} btnLabel={btnLabel} title={title} btnLink={handleSection} />
        {loading || error ?
          <ShapedSkeleton
            width={"100%"} // Adjust width to fit the content
            height={120} // Height to fit the avatar and message
            shape="
                 0 10 1000 63,
                 0 80 24 24, 30 80 100 24, 136 80 100 24
                "
            radius={4} // Rounded corners for rectangles
            fullWidth={false}
          />
          :
          <ListingOrderCommonLog
            uniqueDocuments={uniqueDocuments.slice(0, 5)}
          />}
        {uniqueDocuments && extraObjectsLength > 0 && (
          <div className="view-document">
            <span>+ {extraObjectsLength} more</span>
            <span>
              <button
                className="btn od-btn-secondary interactive"
                onClick={openModal}
              >
                <span>View all {title}s</span>
              </button>
            </span>
          </div>
        )}
      </div>
      <PosModel
        open={isModalOpen}
        onClose={handleCloseModel}
        dialogClassName="set-customer-code-modal-container"
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              <div className="pt-2">
                <h3 className="f20-Inter-500-sab mb-0 mb-0">
                  {`View all ${title}s`}
                </h3>
              </div>
            </span>
            <IconButton
              name="close"
              className="close-icon"
              type="tertiary"
              onClick={handleCloseModel}
            />
          </div>
        }
      >
        <div className="user-order-details">
          <div className="order-manage-note-log" id={`${id}-modal`} key={`${id}-modal`}>
            <ListingOrderCommonLog
              uniqueDocuments={uniqueDocuments}
            />
          </div>
        </div>
      </PosModel>
    </div>
  );
});
